import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  FormLabel,
  TableCell,
  TableRow,
  Checkbox,
  DialogContent,
  Typography,
} from "@mui/material";
import SuiButton from "components/SuiButton";
import StatusDropdown from "components/SuiDropDownList/StatusDropdown";
import SuiInput from "components/SuiInput";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetRoleModuleMutation,
  useGetRoleModulePermissionMutation,
  useAddRoleMutation,
  useAdminRoleByIDMutation,
  useUpdateRoleMutation,
} from "app/features/api/AuthApi";
import { useFormik } from "formik";
import { addRoleSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";

const AddRole = () => {
  const id = localStorage.getItem("admin_role_id");
  const [roleModules, setRoleModules] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const [error, setError] = useState("");
  const [GetRoleModule] = useGetRoleModuleMutation();
  const [GetRoleModulePermission, { isLoading }] = useGetRoleModulePermissionMutation();
  const [addRole] = useAddRoleMutation();
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [adminRoleByID] = useAdminRoleByIDMutation();
  const suiSnackbar = useSuiSnackbar();
  const [updateRole] = useUpdateRoleMutation();
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  const [originalPermissions, setOriginalPermissions] = useState([]);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      admin_role_name: "",
      admin_role_status: "1",
      permissions: {},
    },
    validationSchema: addRoleSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const updatedPermissionsData = await mergePermissions(
        originalPermissions,
        checkedPermissions
      );
      const body = {
        admin_role_name: values.admin_role_name,
        admin_role_status: values.admin_role_status,
        admin_role_permissions: updatedPermissionsData,
      };

      try {
        if (id) {
          // Update role if ID is present
          body.admin_role_id = id;
          let response = await updateRole(body).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/roles-&-permission");
            setError("");
          }
        } else {
          // Add new role if no ID is present
          let response = await addRole(body).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            suiSnackbar(response.message, "success");
            navigate("/roles-&-permission");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });

  // Helper function to merge original and new permissions
  const mergePermissions = async (originalPermissions, newPermissions) => {
    const admin_role_permissions = [];

    // Assuming GetRoleModulePermission().unwrap() retrieves permission data asynchronously
    const permissionResponseData = await GetRoleModulePermission().unwrap();
    const permissionsData = permissionResponseData.data;

    // Process each new permission and map it to admin_role_permissions
    Object.keys(newPermissions).forEach((moduleId) => {
      const modulePermissions = newPermissions[moduleId];
      Object.keys(modulePermissions).forEach((permissionSlug) => {
        if (modulePermissions[permissionSlug]) {
          // Find the permission ID from permissionsData using permissionSlug
          const permissionId = permissionsData.find(
            (perm) => perm.admin_permission_slug === permissionSlug
          )?.admin_permission_id;

          // Push the mapped permission to admin_role_permissions
          if (permissionId) {
            admin_role_permissions.push({
              admin_modules_id: moduleId,
              admin_permission_id: permissionId,
            });
          }
        }
      });
    });

    // Create a copy of admin_role_permissions for merging
    const mergedPermissions = [...admin_role_permissions];

    // Iterate over original permissions and remove those that are not in newPermissions
    originalPermissions.forEach((originalPerm) => {
      const moduleId = originalPerm.admin_modules_id;
      const permissionSlug = originalPerm.admin_permission_slug;

      // If the permission has been removed in newPermissions, remove it from mergedPermissions
      if (!newPermissions[moduleId]?.[permissionSlug]) {
        const index = mergedPermissions.findIndex(
          (perm) =>
            perm.admin_modules_id === moduleId &&
            permissionsData.find((p) => p.admin_permission_id === perm.admin_permission_id)
              ?.admin_permission_slug === permissionSlug
        );
        if (index !== -1) {
          mergedPermissions.splice(index, 1);
        }
      }
    });

    return mergedPermissions;
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setNewStatus(newStatus);
  };

  const handleUpdateClick = (event) => {
    if (id) {
      if (newStatus === "0") {
        event.preventDefault();
        setStatusDialogOpen(true);
      } else {
        formik.handleSubmit(event);
      }
    } else {
      formik.handleSubmit(event);
    }
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      formik.setFieldValue("admin_role_status", newStatus, false);
      formik.submitForm();
    } else {
      formik.setFieldValue("admin_role_status", originalStatus);
    }
    setStatusDialogOpen(false);
  };

  const getRoleDetails = async () => {
    try {
      const response = await adminRoleByID(id).unwrap();
      const { admin_role_name, admin_role_status, admin_role_permissions } = response.data;

      formik.setValues({
        admin_role_name,
        admin_role_status,
        permissions: await transformPermissions(admin_role_permissions),
      });

      setOriginalPermissions(admin_role_permissions);
      initializeCheckedPermissions(admin_role_permissions);
      setOriginalStatus(admin_role_status);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  const initializeCheckedPermissions = async (rolePermissions) => {
    const initialPermissions = {};
    const permissionResponseData = await GetRoleModulePermission().unwrap();
    const permissionsData = permissionResponseData.data;
    rolePermissions.forEach(({ admin_modules_id, admin_permission_id }) => {
      const permissionSlug = permissionsData.find(
        (perm) => perm.admin_permission_id === admin_permission_id
      )?.admin_permission_slug;
      if (permissionSlug) {
        initialPermissions[admin_modules_id] = {
          ...initialPermissions[admin_modules_id],
          [permissionSlug]: true,
        };
        setCheckedPermissions(initialPermissions);
      }
    });
    return initialPermissions;
  };

  const transformPermissions = async (rolePermissions) => {
    const permissionResponseData = await GetRoleModulePermission().unwrap();
    const permissionsData = permissionResponseData.data;

    const transformedPermissions = {};

    rolePermissions.forEach(({ admin_modules_id, admin_permission_id }) => {
      if (!transformedPermissions[admin_modules_id]) {
        transformedPermissions[admin_modules_id] = {};
      }

      const matchedPermission = permissionsData.find(
        (perm) => perm.admin_permission_id === admin_permission_id
      );
      if (matchedPermission) {
        const permissionSlug = matchedPermission.admin_permission_slug;
        transformedPermissions[admin_modules_id][permissionSlug] = true;
      } else {
        console.warn("No matching permission found for admin_permission_id:", admin_permission_id);
      }
    });
    return transformedPermissions;
  };

  const handlePostPaidSelection = () => {};

  const handleCheckboxChange = (moduleId, permissionSlug) => {
    const isChecked = !checkedPermissions[moduleId]?.[permissionSlug];
    if (permissionSlug === "view" && isChecked === false) {
      const hasCreateUpdateDeleteChecked = ["create", "update", "delete"].some(
        (perm) => checkedPermissions[moduleId]?.[perm]
      );
      if (hasCreateUpdateDeleteChecked) {
        suiSnackbar(
          "You cannot uncheck 'view' while 'create', 'update', or 'delete' is checked.",
          "error"
        );
        return;
      }
    }
    let updatedPermissions = {
      ...checkedPermissions,
      [moduleId]: {
        ...checkedPermissions[moduleId],
        [permissionSlug]: isChecked,
      },
    };

    if (
      isChecked &&
      (permissionSlug === "create" || permissionSlug === "update" || permissionSlug === "delete")
    ) {
      updatedPermissions = {
        ...updatedPermissions,
        [moduleId]: {
          ...updatedPermissions[moduleId],
          view: true,
        },
      };
    }

    setCheckedPermissions(updatedPermissions);

    formik.setFieldValue(`permissions.${moduleId}.${permissionSlug}`, isChecked);
  };

  const handleAllCheckboxChange = (moduleId) => {
    const allChecked = !rolePermission.some(
      (permission) => !checkedPermissions[moduleId]?.[permission.admin_permission_slug]
    );

    const modulePermissions = rolePermission.reduce((acc, permission) => {
      acc[permission.admin_permission_slug] = !allChecked;
      return acc;
    }, {});

    const updatedPermissions = {
      ...checkedPermissions,
      [moduleId]: modulePermissions,
    };

    setCheckedPermissions(updatedPermissions);
    formik.setFieldValue(`permissions.${moduleId}`, modulePermissions);
  };

  const showModules = async () => {
    try {
      const response = await GetRoleModule().unwrap();
      if (response.status === 200) {
        setRoleModules(response.data);
      }
      const permissionResponse = await GetRoleModulePermission().unwrap();
      if (permissionResponse.status === 200) {
        setRolePermission(permissionResponse.data);
      }
    } catch (error) {
      if (error.status === 401) {
        navigate("/login");
        suiSnackbar(error.data.message, "error");
      } else {
        suiSnackbar("Something went wrong", "error");
        console.log(error.status);
      }
      setRoleModules([]);
      setRolePermission([]);
    }
  };

  useEffect(() => {
    showModules();
    if (id) {
      getRoleDetails();
    }
  }, [id]);

  const rows =
    roleModules &&
    roleModules.map((item) => {
      const allChecked =
        Array.isArray(rolePermission) &&
        rolePermission.every(
          (permission) =>
            checkedPermissions[item.admin_modules_id]?.[permission.admin_permission_slug]
        );
      return (
        <TableRow key={item.admin_modules_id}>
          <TableCell key={item.admin_modules_id}>{`${item.admin_modules_name}`}</TableCell>
          <TableCell key="0" sx={{ textAlign: "center" }}>
            <Checkbox
              color="secondary"
              checked={allChecked}
              onChange={() => handleAllCheckboxChange(item.admin_modules_id)}
            />
          </TableCell>
          {Array.isArray(rolePermission) &&
            rolePermission.map((permission) => (
              <TableCell key={permission.admin_permission_id} sx={{ textAlign: "center" }}>
                <Checkbox
                  color="secondary"
                  checked={
                    !!checkedPermissions[item.admin_modules_id]?.[permission.admin_permission_slug]
                  }
                  onChange={() =>
                    handleCheckboxChange(item.admin_modules_id, permission.admin_permission_slug)
                  }
                />
              </TableCell>
            ))}
        </TableRow>
      );
    });

  const pagesTableHeader = [
    { id: "moduleName", label: "MODULE NAME" },
    { id: "all", label: "ALL", align: "center" },
    ...(Array.isArray(rolePermission)
      ? rolePermission.map((permission) => ({
          id: permission.admin_permission_slug,
          label: permission.admin_permission_name,
          align: "center",
        }))
      : []),
  ];

  return (
    <>
      <SuiDialog open={statusDialogOpen} onClose={() => handleDialogClose(false)} haveCloseIcon>
        <DialogContent
          sx={{
            textAlign: "center",
          }}
        >
          <h4>Are you sure you want to Inactive this role?</h4>
          <Typography sx={{ mt: 2, fontSize: "14px !important" }}>
            User assigned to this role will not be able to Login/Sign In to portal.
          </Typography>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => handleDialogClose(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    No
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDialogClose(true)}
                  >
                    Yes
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {id ? "Edit Role" : "Add Role"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Role Name
            </FormLabel>
            <SuiInput
              inputSettings={{ autoComplete: "off", required: true }}
              type="text"
              placeholder="Enter Role Name"
              id="admin_role_name"
              name="admin_role_name"
              value={formik.values.admin_role_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.admin_role_name}
              error={formik.errors.admin_role_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="Status" required>
              Status
            </FormLabel>
            <StatusDropdown
              name="admin_role_status"
              value={formik.values.admin_role_status}
              setValue={(e) => {
                formik.handleChange(e);
                handleStatusChange(e);
              }}
              handlePostPaidSelection={handlePostPaidSelection}
            />
            <ErrorMessageComponent
              touched={formik.touched.admin_role_status}
              error={formik.errors.admin_role_status}
            />
          </Grid>
          <Grid item xs={12}>
            <TableToolbar title="Assign Privileges" />
            <EnhancedTable
              tableMinWidth={540}
              tablehead={pagesTableHeader}
              rowsData={rows}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/roles-&-permission"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  disabled={!formik.dirty}
                  onClick={handleUpdateClick}
                >
                  {id ? "Update" : "Add"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddRole;
