import React, { useCallback, useEffect, useState } from "react";
import { Card, FormLabel, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Link, useNavigate } from "react-router-dom";
import { useGetCustomerDetailsMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useFormik } from "formik";
import { useGetLienceValueMutation } from "app/features/api/AuthApi";
import { request3cx } from "Validation";
import { useRequestCreateMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { debounce } from "lodash";

const BuyLiecence = () => {
  const [getRoles, setGetRoles] = useState("");

  const [searchText, setSearchText] = useState("");
  const suiSnackbar = useSuiSnackbar();
  const [getCustomerDetails] = useGetCustomerDetailsMutation();
  const navigateSearch = useNavigate();
  const [requestCreate] = useRequestCreateMutation();
  const formik = useFormik({
    initialValues: {
      customer_name: "",
      customer_name1: "",
      calls: "",
      calls1: "",
      licence_type1: "",
      licence_type: "",
    },
    enableReinitialize: true,
    validationSchema: request3cx,
    onSubmit: async (values) => {
      const body = {
        cus_id: values.customer_name.id,
        license_id: values.licence_type.id,
        sim_calls: Number(values.calls1),
        three_cx_id: values.licence_type.name,
      };
      try {
        const response = await requestCreate(body).unwrap();
        navigateSearch("/3cx-request-approval", { page: 1 });
        suiSnackbar(response.message, "success");
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const options = Array.isArray(getRoles)
    ? getRoles.map((role) => ({
        id: role.cus_id,
        name: `${role.cus_firstname} ${role.cus_lastname}`,
      }))
    : [];

  const getCustomers = async () => {
    try {
      const response = await getCustomerDetails().unwrap();
      setGetRoles(response.data);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };
  const debouncedGetCustomers = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await getCustomerDetails(params).unwrap();
        setGetRoles(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchText.length > 0) {
      debouncedGetCustomers(searchText);
    } else {
      setGetRoles([]);
    }
  }, [searchText, debouncedGetCustomers]);

  const [GetLienceValue] = useGetLienceValueMutation();

  useEffect(() => {
    const fetchDropdownValues = async () => {
      try {
        const response = await GetLienceValue().unwrap();
        setDropVal(response.data);
      } catch (error) {
        console.error("Error fetching license values:", error);
      }
    };

    fetchDropdownValues();
  }, [GetLienceValue]);
  useEffect(() => {
    getCustomers();
  }, []);
  const [dropvals, setDropVal] = useState([]);

  const licenseEdition = dropvals?.licences?.map((dropval) => ({
    id: dropval.license_id,
    name: dropval.license_name,
  }));

  const calls = dropvals?.simultaneous_call?.map((dropval) => ({
    id: dropval.sim_call_id,
    name: dropval.sim_call_value,
  }));

  return (
    <Card sx={{ my: 3, p: 3 }}>
      <Grid component="form" container spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Assign Licence
          </SuiTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="role_name" required>
            Customer Name
          </FormLabel>

          <AutocompleteSingleSelect
            id="customer_name"
            value={formik.values.customer_name}
            setValue={(value) => {
              formik.setFieldValue("customer_name", value);
              formik.setFieldValue("customer_name1", value.name);
            }}
            noOptionsText="No options"
            placeholder="Select Customer Name"
            isLoading={false}
            disabled={false}
            onInputChange={debouncedGetCustomers}
            options={options}
            formik={formik}
          />
          {formik.errors.customer_name1 && formik.touched.customer_name && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
              {formik.errors.customer_name1}
            </div>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="role_name" required>
            Licence Edition
          </FormLabel>

          <AutocompleteSingleSelect
            id="licence_type"
            value={formik.values.licence_type}
            setValue={(value) => {
              formik.setFieldValue("licence_type", value);
              formik.setFieldValue("licence_type1", value.name);
            }}
            placeholder="Select Licence Edition"
            isLoading={false}
            disabled={false}
            options={licenseEdition}
            formik={formik}
          />
          {formik.errors.licence_type1 && formik.touched.licence_type && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.licence_type1}</div>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="status" required>
            Simultaneous Calls
          </FormLabel>
          <AutocompleteSingleSelect
            id="calls"
            value={formik.values.calls}
            setValue={(value) => {
              formik.setFieldValue("calls", value);
              formik.setFieldValue("calls1", value.name);
            }}
            placeholder="Select Simultaneous Calls"
            isLoading={false}
            disabled={false}
            options={calls}
            formik={formik}
          />
          {formik.errors.calls1 && formik.touched.calls && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.calls1}</div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
            <Grid item>
              <SuiButton
                size="small"
                component={Link}
                to="/3cx-request-approval"
                variant="outlined"
                buttonColor="dark"
                sx={{ px: "1rem", py: "0.4rem" }}
              >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                type="submit"
                onClick={formik.handleSubmit}
                size="small"
                buttonColor="primary"
                sx={{ px: "1rem" }}
              >
                Submit
              </SuiButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BuyLiecence;
