import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from "@mui/material";
/* eslint-disable no-undef */
import DashboardCard from "Container/Cards/DashboardCard";
import { setDashboardCounts } from "app/features/DashboardSlice";
import { useGetDashboardInfoMutation } from "app/features/api/DashboardApi";
import SuiDateRangePicker from "components/SuiDateRangePicker";
import IconButton from "@mui/material/IconButton";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import totalCustomer from "../../assets/images/dashboard/total_customer.svg";
import totalDid from "../../assets/images/dashboard/total_dids.svg";
import totalSip from "../../assets/images/dashboard/total_sip.svg";
import totalOrders from "../../assets/images/dashboard/total_orders.svg";
import totalPayments from "../../assets/images/dashboard/total_payment.svg";
import totalUsers from "../../assets/images/dashboard/total_users.svg";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import colors from "assets/theme/base/colors";
import bg_object1 from "assets/images/bg_object.png";
import bg_object2 from "assets/images/bg_object2.png";
import CXLOGO from "assets/images/3cx-logo.png";
import DefaultDoughnutLicence from "components/Chart/DoughnutCharts";
import DefaultDoughnutChart from "components/Chart/DoughnutCharts/DefaultDoughnutChart";
import { Link, useNavigate } from "react-router-dom";
import { useLastDidsMutation } from "app/features/api/AuthApi";
import { useLastCustomersMutation } from "app/features/api/AuthApi";
import { useLastOrderMutation } from "app/features/api/AuthApi";
import { useThree3cxCountsMutation } from "app/features/api/AuthApi";
import { formatDateNew } from "helper";
import { doughnutCharDataFn } from "layouts/DID/data";
import { doughnutCharDataLc } from "layouts/DID/data";
import EyeIcon from "Container/Icons/EyeIcon";
import { setOrderDetails } from "app/features/CustomeDetailsSlice";
import { useGetOrderDetailsMutation } from "app/features/api/CustomerDetailsApi";
import Nodata from "layouts/customer/components/Nodata";
import SuiDialog from "components/SuiDialog";
import DefaultSubscriptionChart from "components/Chart/DoughnutCharts/DefaultSubscriptionChart";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
  BarElement,
  Legend,
} from "chart.js";
import { CutomeTabs } from "components/CutomeTab";
import { CutomeTab } from "components/CutomeTab";
import { useSubscriptionCountsMutation } from "app/features/api/AuthApi";
import DateSelectionDropdown from "components/SuiDropDownList/DateSelectionDropdown";
import { useCustRegistrationCountsMutation } from "app/features/api/AuthApi";
import CommonSpinner from "components/CommonSpinner";
import { useCustStatusCountsMutation } from "app/features/api/AuthApi";

// Register the necessary components for Chart.js
Chart.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const [lastDids] = useLastDidsMutation();
  const [lastCustomers] = useLastCustomersMutation();
  const [lastOrders] = useLastOrderMutation();
  const [dashboar3cxCounts] = useThree3cxCountsMutation();
  const [subScriptionCount] = useSubscriptionCountsMutation();
  const [custRegistrationCounts] = useCustRegistrationCountsMutation();
  const [custStatusCounts] = useCustStatusCountsMutation();
  const [lastDidsInfo, setlastDidsInfo] = useState();
  const [lastCustomerInfo, setlastCustomerInfo] = useState();
  const [getOrderDetails, { isLoading: isLoading }] = useGetOrderDetailsMutation();
  const [lastOrderInfo, setlastOrderInfo] = useState();
  const [getDashboardInfo] = useGetDashboardInfoMutation();
  const [dashboard3cxCounts, setdashboard3cxCounts] = useState();
  const [subScriptionCounts, setsubScriptionCount] = useState();
  const { orders } = useSelector((state) => state.customerDetails);
  const [dialog, setDialog] = useState({ open: false, order_id: "" });
  const [tabValue, setTabValue] = useState(1);
  const [custCount, setcustCount] = useState("");
  const [custStatusCount, setcustStatusCount] = useState("");
  const [chartLoading, setChartLoading] = useState(false);
  const [chartStatusLoading, setChartStatusLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      range: "month",
      startDate: "",
      endDate: "",
      cust_registration: "6",
      cust_status_count: "6",
    },
    // validationSchema: {},
    onSubmit: async () => {
      // console.log(values, "values");
    },
  });

  const dispatch = useDispatch();
  const handlePostPaidSelection = () => {};
  const handleTabVal = (event, newValue) => {
    setTabValue(newValue);
    showCustStatusData(formik.values.cust_status_count, newValue);
  };
  let showCustRegistrationData = async (currentDropVal) => {
    setChartLoading(true);

    const dropVal = Number(currentDropVal);
    const today = new Date();
    const pastDate = new Date(today);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();

    // Adjust pastDate based on currentDropVal
    if (dropVal === 3) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(currentMonth - 3);
      pastDate.setDate(1);
      today.setMonth(currentMonth - 1);
      today.setDate(lastDayOfMonth);
    } else if (dropVal === 6) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(currentMonth - 6);
      pastDate.setDate(1);
      today.setMonth(currentMonth - 1);
      today.setDate(lastDayOfMonth);
    } else if (dropVal === 12) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(0);
      pastDate.setDate(1);
    } else {
      console.log("Condition not matched");
    }

    const formattedPreviousDate = dayjs(pastDate).format("YYYY-MM-DD");
    const formattedFinishDate = dayjs(today).format("YYYY-MM-DD");

    let urlParamss = {
      start_date: formattedPreviousDate,
      end_date: formattedFinishDate,
    };
    try {
      const response = await custRegistrationCounts(urlParamss).unwrap();
      setcustCount(response.data);
      const dates = response.data.map((item) => item.date);
      const counts = response.data.map((item) => item.count);

      setLineData({
        labels: dates,
        datasets: [
          {
            label: "Count",
            data: counts,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            pointBackgroundColor: "rgba(75, 192, 192, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(75, 192, 192, 1)",
            pointStyle: "circle",
            pointRadius: 5,
            pointHoverRadius: 7,
            fill: false,
          },
        ],
      });
      // console.log("setLineData:::", lineData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setChartLoading(false);
  };

  let showCustStatusData = async (currentDropVal, accountType) => {
    setChartStatusLoading(true);

    const dropValset = Number(currentDropVal);
    const today = new Date();
    const pastDate = new Date(today);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();

    // Adjust pastDate based on currentDropVal
    if (dropValset === 3) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(currentMonth - 3);
      pastDate.setDate(1);
      today.setMonth(currentMonth - 1);
      today.setDate(lastDayOfMonth);
    } else if (dropValset === 6) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(currentMonth - 6);
      pastDate.setDate(1);
      today.setMonth(currentMonth - 1);
      today.setDate(lastDayOfMonth);
    } else if (dropValset === 12) {
      pastDate.setFullYear(currentYear);
      pastDate.setMonth(0);
      pastDate.setDate(1);
    } else {
      console.log("Condition not matched");
    }

    const formattedPreviousDate = dayjs(pastDate).format("YYYY-MM-DD");
    const formattedFinishDate = dayjs(today).format("YYYY-MM-DD");

    let urlParamss = {
      start_date: formattedPreviousDate,
      end_date: formattedFinishDate,
      cus_status_account_type: accountType,
    };

    try {
      const response = await custStatusCounts(urlParamss).unwrap();
      setcustStatusCount(response.data);
      // Extract labels and datasets from the API response
      const dates = response.data.map((item) => item.cus_status_date);
      const activeCounts = response.data.map((item) => item.cus_status_active_count);
      const inactiveCounts = response.data.map((item) => item.cus_status_inactive_count);
      const blockedCounts = response.data.map((item) => item.cus_status_blocked_count);
      const restrictedCounts = response.data.map((item) => item.cus_status_restricted_count);

      // Update the barData
      const barData = {
        labels: dates, // Use the dates from the API response as labels
        datasets: [
          {
            label: "Active",
            data: activeCounts,
            backgroundColor: "#55DED0",
            borderColor: "#55DED0",
            borderWidth: 1,
          },
          {
            label: "Inactive",
            data: inactiveCounts,
            backgroundColor: "#364FB3",
            borderColor: "#364FB3",
            borderWidth: 1,
          },
          {
            label: "Blocked",
            data: blockedCounts,
            backgroundColor: "#C02B0A",
            borderColor: "#C02B0A",
            borderWidth: 1,
          },
          {
            label: "Restricted",
            data: restrictedCounts,
            backgroundColor: "#848484",
            borderColor: "#848484",
            borderWidth: 1,
          },
        ],
      };

      // Set the barData in your state (assuming you have a setBarData function)
      console.log("barData:::", barData);
      setBarData(barData);
      setChartStatusLoading(false);
    } catch (error) {
      console.error("Failed to fetch customer status counts", error);
      setChartStatusLoading(false);
    }
  };

  const [barData, setBarData] = useState({
    labels: [], // Use the dates from the API response as labels
    datasets: [
      {
        label: "Active",
        data: [],
        backgroundColor: "#55DED0",
        borderColor: "#55DED0",
        borderWidth: 1,
      },
      {
        label: "Inactive",
        data: [],
        backgroundColor: "#364FB3",
        borderColor: "#364FB3",
        borderWidth: 1,
      },
      {
        label: "Blocked",
        data: [],
        backgroundColor: "#C02B0A",
        borderColor: "#C02B0A",
        borderWidth: 1,
      },
      {
        label: "Restricted",
        data: [],
        backgroundColor: "#848484",
        borderColor: "#848484",
        borderWidth: 1,
      },
    ],
  });

  const [lineData, setLineData] = useState({
    labels: [], // This will be updated with dates from the API response
    datasets: [
      {
        label: "Count",
        data: [], // This will be updated with count data from the API response
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(75, 192, 192, 1)",
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: false,
      },
    ],
  });

  const lineOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...lineData.datasets[0].data) + 10, // Adjust Y-axis max based on data
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Count: ${context.raw}`;
          },
        },
      },
    },
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...barData.datasets[0].data) + 10,
      },
    },
  };

  const { counts } = useSelector((state) => state.dashboard);

  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "DID number" },
    { id: "2", align: "left", width: "auto", label: "Number Type" },
    { id: "3", align: "left", width: "auto", label: "Account Number" },
    { id: "4", align: "left", width: "auto", label: "Price" },
  ];

  const pagesTableHeaderOrder = [
    { id: "1", align: "left", width: "auto", label: "Order ID" },
    { id: "3", align: "left", width: "auto", label: "Account Number" },
    { id: "2", align: "left", width: "auto", label: "Order Type" },
    { id: "5", align: "left", width: "auto", label: "Amount" },
    { id: "4", align: "left", width: "auto", label: "Purchased on" },
  ];

  const pagesTableHeaderCustomer = [
    { id: "3", align: "left", width: "auto", label: "Account Number" },
    { id: "1", align: "left", width: "auto", label: "Company Name" },
    { id: "2", align: "left", width: "auto", label: "Account Type" },
    { id: "4", align: "left", width: "auto", label: "Created on" },
  ];
  const navigateSearch = useNavigate();
  const editRedirect = (item, i) => {
    if (i == "cus") {
      navigateSearch(
        `/customer/edit-customer/account-details/${item?.cus_id}/${item?.cus_account_number}`
      );
      localStorage.setItem("cus_id", item?.cus_account_type);
      localStorage.setItem("customer_id", item?.cus_id);
    }
    if (i == "did") {
      navigateSearch(
        `/customer/edit-customer/account-details/${item?.cus_id}/${item?.cus_account_number}`
      );
      localStorage.setItem("cus_id", item?.customers?.cus_account_type);
      localStorage.setItem("customer_id", item?.cus_id);
    }
    if (i == "order") {
      navigateSearch(
        `/customer/edit-customer/account-details/${item?.customers?.cus_id}/${item?.customers?.cus_account_number}`
      );
      localStorage.setItem("cus_id", item?.customers?.cus_account_type);
      localStorage.setItem("customer_id", item?.customers?.cus_id);
    }
  };
  const PopupItem = ({ title, subTitle, channel }) => (
    <Box display="flex" alignItems="start" justifyContent="space-between" gap={1.5} flexWrap="wrap">
      <SuiTypography variant="h6" fontSize={{ xs: 14, md: 15, xl: 16 }}>
        {title}
      </SuiTypography>
      <SuiTypography
        component="p"
        variant="body2"
        sx={{ color: `${colors.text.focus} !important` }}
        fontSize={{ xs: 13, md: 14.5, xl: 14 }}
      >
        {channel}
      </SuiTypography>
      <SuiTypography
        component="p"
        variant="body2"
        fontSize={{ xs: 13, md: 14.5, xl: 14 }}
        sx={{ color: `${colors.text.focus} !important` }}
      >
        {subTitle}
      </SuiTypography>
    </Box>
  );

  const PopupItems = ({ heading, children }) => (
    <Grid container justifyContent="start" py={3} px={1.5} rowSpacing={2}>
      <Grid item>
        <SuiTypography
          px={2}
          py={0.5}
          bgcolor={colors.primary.main}
          borderRadius="20px"
          fontSize={14}
        >
          {heading}
        </SuiTypography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="start" rowSpacing={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
  const rows =
    lastDidsInfo?.dids && Array.isArray(lastDidsInfo?.dids)
      ? lastDidsInfo?.dids.map((item, i) => {
          const labelId = `item.cus_id_${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell>{item.did_number}</TableCell>
                <TableCell>{item.did_number_type}</TableCell>
                <TableCell
                  onClick={() => editRedirect(item, "did")}
                  sx={{
                    cursor: "pointer",
                    color: "#364FB3",
                    textDecoration: "underline",
                    textDecorationColor: "#364FB3",
                  }}
                >
                  {item.cus_account_number == null ? "-" : item.cus_account_number}
                </TableCell>
                <TableCell>£{item.did_price}</TableCell>
              </TableRow>
            </>
          );
        })
      : [];
  const orderRows =
    lastOrderInfo?.orders && Array.isArray(lastOrderInfo?.orders)
      ? lastOrderInfo?.orders.map((item, i) => {
          const labelId = `item.cus_id_${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell sx={{ minWidth: "200px" }}>{item.order_id}</TableCell>
                <TableCell
                  sx={{
                    cursor: "pointer",
                    color: "#364FB3",
                    textDecoration: "underline",
                    textDecorationColor: "#364FB3",
                  }}
                  onClick={() => editRedirect(item, "order")}
                >
                  {item.customers.cus_account_number}
                </TableCell>
                <TableCell>
                  {item.orders.order_type == null ? "-" : item.orders.order_type}
                </TableCell>
                <TableCell>£{item.orders.order_total_price}</TableCell>
                <TableCell sx={{ minWidth: "160px" }}>
                  {formatDateNew(item.orders.createdAt)}
                  {item.orders.order_type == "checkout" ? (
                    <IconButton
                      className="viewIcon"
                      onClick={() => {
                        getOrderDetailsFunc(item.orders.order_id);
                      }}
                    >
                      <EyeIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            </>
          );
        })
      : [];

  const customerRows = lastCustomerInfo?.customers.map((item, i) => {
    const labelId = `item.cus_id_${i}`;
    return (
      <>
        <TableRow key={labelId}>
          <TableCell
            sx={{
              cursor: "pointer",
              color: "#364FB3",
              textDecoration: "underline",
              textDecorationColor: "#364FB3",
            }}
            onClick={() => editRedirect(item, "cus")}
          >
            {item.cus_account_number}
          </TableCell>
          <TableCell>{item.cus_company_name}</TableCell>
          <TableCell>{item.cus_account_type == 1 ? "Prepaid" : "Postpaid"}</TableCell>
          <TableCell>{formatDateNew(item.createdAt)}</TableCell>
        </TableRow>
      </>
    );
  });

  const getDashboardInfoFunc = async () => {
    const range =
      formik.values.range === "week"
        ? 12
        : formik.values.range === "month"
        ? 13
        : formik.values.range === "year"
        ? 14
        : 15;

    const body =
      range !== 15
        ? { report_type: range }
        : {
            start_date: dayjs(new Date(formik.values.startDate)).format("YYYY-MM-DD"),
            end_date: dayjs(new Date(formik.values.endDate)).format("YYYY-MM-DD"),
            report_type: range,
          };
    try {
      const response = await getDashboardInfo(body).unwrap();
      dispatch(setDashboardCounts(response?.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    if (formik.values.startDate !== null && formik.values.endDate !== null) {
      getDashboardInfoFunc();
    }
    localStorage.removeItem("cus_id");
    localStorage.removeItem("customer_id");
  }, [permissions, formik.values.range, formik.values.startDate, formik.values.endDate]);

  const handleDateChange = (a) => {
    if (a?.length) {
      formik.setFieldValue("startDate", new Date(a[0]));
      formik.setFieldValue("endDate", new Date(a[1]));
    } else {
      formik.setFieldValue("startDate", null);
      formik.setFieldValue("endDate", null);
    }
  };

  const getLastDids = async () => {
    try {
      const [response, customer, order, dashboardC, subscriptionC] = await Promise.all([
        lastDids().unwrap(),
        lastCustomers().unwrap(),
        lastOrders().unwrap(),
        dashboar3cxCounts().unwrap(),
        subScriptionCount().unwrap(),
      ]);
      setlastDidsInfo(response.data);
      setlastCustomerInfo(customer.data);
      setlastOrderInfo(order.data);
      setdashboard3cxCounts(dashboardC.data);
      setsubScriptionCount(subscriptionC.data);
      showCustRegistrationData(6);
      showCustStatusData(6, tabValue);
    } catch (error) {
      console.log(error);
    }
  };
  const activeValue = Number(dashboard3cxCounts?.threecxCounts?.active) || 0;
  const expireValue = Number(dashboard3cxCounts?.threecxCounts?.expired) || 0;
  const active = isNaN(activeValue) ? 0 : activeValue;
  const expire = isNaN(expireValue) ? 0 : expireValue;
  const ActiveCountValue = Number(dashboard3cxCounts?.three3xRequestCounts?.approved) || 0;
  const PendingValue = Number(dashboard3cxCounts?.three3xRequestCounts?.pending) || 0;
  const RejectValue = Number(dashboard3cxCounts?.three3xRequestCounts?.rejected) || 0;
  const activeVal = isNaN(ActiveCountValue) ? 0 : ActiveCountValue;
  const PendingVal = isNaN(PendingValue) ? 0 : PendingValue;
  const RejectVal = isNaN(RejectValue) ? 0 : RejectValue;
  const activeSubscriptionValue = Number(subScriptionCounts?.active) || 0;
  const cancelSubscriptionValue = Number(subScriptionCounts?.cancelled) || 0;
  const cancelSoonSubscriptionValue = Number(subScriptionCounts?.cancelSoon) || 0;
  useEffect(() => {
    getLastDids();
  }, []);

  const getOrderDetailsFunc = async (id) => {
    setDialog({ open: true, order_id: id });
    try {
      const response = await getOrderDetails(id).unwrap();
      dispatch(setOrderDetails(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  let sipOrderLogs = [];
  let sipCallBundleOrderLogs = [];
  const siplogs = orders?.map((order) => {
    if (order?.order_sip_username !== null && order?.order_sip_channels !== null) {
      sipOrderLogs.push({
        sipName: order?.order_sip_username,
        status: order?.order_logs_status,
        sipChannel: order?.order_sip_channels,
      });
      // setOrderLog(sipOrderLogs?.map((item) => item?.callBundle));
    } else {
      sipCallBundleOrderLogs.push({
        sipName: order?.order_sip_username,
        status: order?.order_logs_status,
        callBundle: order?.order_sip_call_bundle,
        callBundleChannel: order?.order_sip_call_bundle_units,
      });
    }
  });
  let didOrderLogs = [];
  const didlogs = orders?.map((order) => {
    if (order?.order_number !== null) {
      didOrderLogs.push({
        didNumber: order?.order_number,
        status: order?.order_logs_status,
      });
    }
  });
  console.log(siplogs, didlogs);

  return (
    <>
      {permissionInfo?.dashboard?.VIEW ? (
        <Grid component="section" container py={3} spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent={{ xs: "start", lg: "space-between" }}
            >
              <Grid item>
                <SuiTypography fontWeight="medium">Welcome Back!</SuiTypography>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    sx={{
                      alignItems: "center",
                      rowGap: 1.5,
                      "&>.MuiFormControlLabel-root": {
                        marginRight: 1,
                      },
                    }}
                    name="range"
                    id="range"
                    value={formik.values.range}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("date_range", "");
                      formik.handleSubmit();
                    }}
                  >
                    <FormControlLabel value="week" control={<Radio />} label="This Week" />
                    <FormControlLabel value="month" control={<Radio />} label="This Month" />
                    <FormControlLabel value="year" control={<Radio />} label="This Year" />
                    <FormControlLabel
                      value="custom_range"
                      control={<Radio />}
                      label="Custom Range"
                    />
                    <SuiDateRangePicker
                      disabled={formik.values.range !== "custom_range"}
                      value={
                        formik.values.startDate === "" && formik.values.endDate === ""
                          ? []
                          : formik.values.startDate === null && formik.values.endDate === null
                          ? []
                          : [new Date(formik.values.startDate), new Date(formik.values.endDate)]
                      }
                      onDateChange={handleDateChange}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total customers
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {counts?.total_customer_count}
                </SuiTypography>
              </Box>
              <img src={totalCustomer} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total DIDs
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {counts?.total_did_count}
                </SuiTypography>
              </Box>
              <img src={totalDid} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total SIP Trunks
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {counts?.total_sip_trunk_count}
                </SuiTypography>
              </Box>
              <img src={totalSip} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total Orders
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {counts?.total_order_count}
                </SuiTypography>
              </Box>
              <img src={totalOrders} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total Payment Received
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {`£ ${counts?.total_payment}`}
                </SuiTypography>
              </Box>
              <img src={totalPayments} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardCard>
              <Box>
                <SuiTypography
                  component="p"
                  fontSize={{ xs: 14, lg: 16 }}
                  fontWeight={500}
                  textColor="text2"
                >
                  Total No. of Users
                </SuiTypography>
                <SuiTypography component="h1" fontSize={{ xs: 36, lg: 38 }} fontWeight={600}>
                  {counts?.total_admin_user_count ? counts?.total_admin_user_count : 0}
                </SuiTypography>
              </Box>
              <img src={totalUsers} width="50" />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Card sx={{ width: "100%" }}>
              <TableToolbar title="Last 5 Purchased DIDs">
                {permissionInfo.didNum.VIEW && (
                  <SuiTypography
                    textColor="info"
                    fontSize="14px"
                    component={Link}
                    sx={{ cursor: "pointer" }}
                    to={"/did?page=1"}
                  >
                    View All
                  </SuiTypography>
                )}
              </TableToolbar>
              <EnhancedTable tableMinWidth={540} tablehead={pagesTableHeader} rowsData={rows} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius={{ xs: "17px", lg: "20px" }}
              height="195px"
              mb={2}
              px={{ xs: 1.5, lg: 3 }}
              py={2.5}
              color="#fff"
              overflow="hidden"
              bgcolor={colors.primary.main}
              position="relative"
              sx={{
                "&::before": {
                  position: "absolute",
                  content: "''",
                  width: "100%",
                  height: "100%",
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                  background: `url(${bg_object2})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                },
              }}
            >
              <Box position="relative" zIndex={1} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-10px",
                    top: "20px",
                  }}
                >
                  <img src={CXLOGO} alt="3CXLOGO" width="90px" />
                </Box>
                <DefaultDoughnutLicence
                  chart={doughnutCharDataLc(
                    "Total Licence",
                    ["Active Licence", "Expired Licence"],
                    [active, expire],
                    [colors.info.main, colors.light.main]
                  )}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: "-5px",
                    bottom: "20px",
                  }}
                >
                  {permissionInfo.threecx_licences.VIEW && (
                    <SuiTypography
                      textColor="white"
                      fontSize="14px"
                      component={Link}
                      sx={{ cursor: "pointer" }}
                      to={"/3cx-details"}
                    >
                      View All
                    </SuiTypography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius={{ xs: "17px", lg: "20px" }}
              height="195px"
              px={{ xs: 1.5, lg: 3 }}
              py={2.5}
              color="#fff"
              overflow="hidden"
              bgcolor={colors.primary.main}
              position="relative"
              sx={{
                "&::before": {
                  position: "absolute",
                  content: "''",
                  width: "100%",
                  height: "100%",
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                  background: `url(${bg_object1})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                },
              }}
            >
              <Box position="relative" zIndex={1} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-10px",
                    top: "20px",
                  }}
                >
                  <img src={CXLOGO} alt="3CXLOGO" width="90px" />
                </Box>
                <DefaultDoughnutChart
                  chart={doughnutCharDataFn(
                    "Total 3CX requests",
                    ["Pending", "Approved", "Rejected"],
                    [PendingVal, activeVal, RejectVal],
                    [colors.light.main, colors.info.main, colors.error.main]
                  )}
                  id={1}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: "-5px",
                    bottom: "20px",
                  }}
                >
                  {permissionInfo.threecx_licences_request.VIEW && (
                    <SuiTypography
                      textColor="white"
                      fontSize="14px"
                      component={Link}
                      to={"/3cx-request-approval"}
                      sx={{ cursor: "pointer" }}
                    >
                      View All
                    </SuiTypography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ width: "100%" }} className="order-details">
              <TableToolbar title="Last 5 Orders" />
              <EnhancedTable tablehead={pagesTableHeaderOrder} rowsData={orderRows} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ width: "100%" }} className="order-details">
              <TableToolbar title="Last 5 Customer Registrations">
                {permissionInfo.customer.VIEW && (
                  <SuiTypography
                    textColor="info"
                    fontSize="14px"
                    component={Link}
                    sx={{ cursor: "pointer" }}
                    to={"/customer"}
                  >
                    View All
                  </SuiTypography>
                )}
              </TableToolbar>
              <EnhancedTable tablehead={pagesTableHeaderCustomer} rowsData={customerRows} />
            </Card>
          </Grid>

          <SuiDialog
            open={dialog.open}
            onClose={() => {
              setDialog({ open: false, order_id: "" });
              dispatch(setOrderDetails([]));
            }}
            haveCloseIcon
            closeByBackdrop
          >
            <Grid
              container
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="center"
              px={{ xs: 1.5, lg: 2 }}
              py={2}
            >
              <Grid item>
                <SuiTypography fontWeight="semiBold" variant="h6">
                  Product Details for : {dialog.order_id}
                </SuiTypography>
              </Grid>
            </Grid>
            <hr style={{ borderColor: "#D5D5D5", marginTop: "0px", marginBottom: "0px" }} />
            <Box height="100%" sx={{ overflowY: "overlay" }}>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "115px",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {sipOrderLogs?.length === 0 &&
                    didOrderLogs?.length === 0 &&
                    sipCallBundleOrderLogs?.length === 0 && <Nodata />}
                  {sipOrderLogs?.length > 0 && (
                    <PopupItems heading="SIP Trunk">
                      {sipOrderLogs?.map((order) => {
                        return (
                          <Grid item xs={12} key={order.sipName}>
                            <PopupItem
                              title={order.sipName}
                              subTitle={`Status: ${order?.status}`}
                              channel={`No. of Channels: ${order?.sipChannel}`}
                            />
                          </Grid>
                        );
                      })}
                    </PopupItems>
                  )}
                  {sipCallBundleOrderLogs?.length > 0 &&
                    sipCallBundleOrderLogs.some((order) => order.callBundle !== null) && (
                      <PopupItems heading="Call Bundles">
                        {sipCallBundleOrderLogs?.map((order) => {
                          if (order.callBundle !== null) {
                            return (
                              <Grid key={order.id} item xs={12}>
                                <PopupItem
                                  title={`${order?.sipName} - ${order.callBundle}`}
                                  subTitle={`Status: ${order?.status}`}
                                  channel={`No. of Bundles: ${order?.callBundleChannel}`}
                                />
                              </Grid>
                            );
                          } else {
                            return null; // Skip rendering if callBundle is null
                          }
                        })}
                      </PopupItems>
                    )}
                  {didOrderLogs?.length > 0 && (
                    <PopupItems heading="DID Numbers">
                      {didOrderLogs?.map((order) => {
                        return (
                          <Grid item xs={12} key={order.didNumber}>
                            <PopupItem
                              title={order.didNumber}
                              subTitle={`Status: ${order.status}`}
                            />
                          </Grid>
                        );
                      })}
                    </PopupItems>
                  )}
                </>
              )}
            </Box>
          </SuiDialog>
          {/* <Grid item xs={12} sm={6} md={7}>
            <Card>
              <TableContainer sx={{ "tr:last-of-type td": { border: "none" } }}>
                <Table sx={{ minWidth: "540px" }} size="medium">
                  <TableRow>
                    <TableCell>DID number</TableCell>
                    <TableCell>Number Type</TableCell>
                    <TableCell>Account Number</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>000 123 456 789</TableCell>
                      <TableCell>National</TableCell>
                    <TableCell>
                      <SuiTypography fontSize="16px" fontWeight="400" textColor="info">
                        CUP2001
                      </SuiTypography>
                    </TableCell>
                    <TableCell>£12.00</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Card>
          </Grid> */}
          {/* Subscritipon graph :: BEGIN */}
          <Grid item xs={12} md={4} xl={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderRadius={{ xs: "17px", lg: "20px" }}
              px={{ xs: 1.5, lg: 3 }}
              py={2.5}
              color="#fff"
              overflow="hidden"
              bgcolor={colors.info.main}
              position="relative"
              sx={{
                "&::before": {
                  position: "absolute",
                  content: "''",
                  width: "100%",
                  height: "100%",
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                  background: `url(${bg_object2})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                },
              }}
            >
              <Box position="relative" sx={{ width: "100%" }} zIndex={1}>
                <DefaultSubscriptionChart
                  id="did_chart"
                  chart={doughnutCharDataFn(
                    "Total",
                    [
                      "Active Subscriptions",
                      "Cancelled Subscriptions",
                      "Cancel Soon Subscriptions",
                    ],
                    [activeSubscriptionValue, cancelSubscriptionValue, cancelSoonSubscriptionValue],
                    [colors.primary.main, colors.error.main, colors.text.focus]
                  )}
                />
              </Box>
            </Box>
          </Grid>
          {/* Subscritipon graph :: END */}
          {/* Customer Registrations :: BEGIN */}
          <Grid item xs={12} md={8} xl={8}>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item xs={12} md={6}>
                  <SuiTypography
                    textColor="dark"
                    fontSize="18px"
                    fontWeight="600"
                    customClass="header-title"
                  >
                    Customer Registrations
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} />
                <Grid item xs={12} md={3} lg={3}>
                  <DateSelectionDropdown
                    name="cust_registration"
                    value={formik.values.cust_registration}
                    setValue={(e) => {
                      formik.handleChange(e);
                      showCustRegistrationData(e.target.value);
                    }}
                    handlePostPaidSelection={handlePostPaidSelection}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box pt={0}>
                    {chartLoading ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "400px" }}
                      >
                        <CommonSpinner size="80" color="#55ded0" />
                      </Box>
                    ) : custCount?.length > 0 ? (
                      <Line data={lineData} options={lineOptions} />
                    ) : (
                      <Nodata />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Customer Registrations :: END */}
          {/* Customer status overview :: BEGIN */}
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item xs={12} md={3}>
                  <SuiTypography
                    textColor="dark"
                    fontSize="18px"
                    fontWeight="600"
                    customClass="header-title"
                  >
                    Customer status overview
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} />
                <Grid item xs={12} md={3} lg={3}>
                  <DateSelectionDropdown
                    name="cust_status_count"
                    value={formik.values.cust_status_count}
                    setValue={(e) => {
                      formik.handleChange(e);
                      showCustStatusData(e.target.value, tabValue);
                    }}
                    handlePostPaidSelection={handlePostPaidSelection}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <CutomeTabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={tabValue}
                    onChange={handleTabVal}
                  >
                    <CutomeTab label="Prepaid" value={1} />
                    <CutomeTab label="Postpaid" value={0} />
                  </CutomeTabs>
                </Grid>
                <Grid item xs={12}>
                  {tabValue === 0 ? (
                    <Box pt={0}>
                      {chartLoading ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "300px" }}
                        >
                          <CommonSpinner size="80" color="#55ded0" />
                        </Box>
                      ) : custStatusCount?.length > 0 ? (
                        <Bar data={barData} options={barOptions} />
                      ) : (
                        <Nodata />
                      )}
                    </Box>
                  ) : (
                    <Box p={2} pt={0}>
                      {chartStatusLoading ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "300px" }}
                        >
                          <CommonSpinner size="80" color="#55ded0" />
                        </Box>
                      ) : custStatusCount?.length > 0 ? (
                        <Bar data={barData} options={barOptions} />
                      ) : (
                        <Nodata />
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Customer status overview :: END */}
        </Grid>
      ) : (
        <Grid item>
          <SuiTypography
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
            }}
            fontWeight="medium"
          >
            Welcome Back!
          </SuiTypography>
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
