import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseAPIService } from "./features/api";
import AuthReducer from "./features/AuthSlice";
import UIControllerReducer from "./features/UIControllerSlice";
import DashboardReducer from "./features/DashboardSlice";

import permissionReducer from "./features/api/permissionSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { customerDetailsSlice } from "./features/CustomeDetailsSlice";

const authPersistConfig = {
  key: "auth",
  storage,
};
const customerDetailsPersistConfig = {
  key: "customerDetails",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  dashboard: DashboardReducer,
  customerDetails: persistReducer(customerDetailsPersistConfig, customerDetailsSlice.reducer),
  uiControl: UIControllerReducer,
  permission: permissionReducer,
  [baseAPIService.reducerPath]: baseAPIService.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseAPIService.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export default store;
