import { useState } from "react";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import styles from "Container/Sidenav/styles/sidenavCollapse";
import SubLink from "./SubLink";
import { Icon } from "@mui/material";
import SuiBox from "components/SuiBox";

const SidenavCollapse = ({ icon, name, subMenu }) => {
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav, sidenavColor } = uiControl;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  let active = subMenu.map(({ link }) => {
    if (link === `/${collapseName}`) {
      return true;
    }
    return false;
  });
  active = active.includes(true);
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });
  const [expanded, setExpanded] = useState(active);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(!expanded)}
      className={classes.collapse_item}
    >
      <AccordionSummary className={classes.collapse_summary} expandIcon={<ExpandMoreIcon />}>
        <ListItemIcon className={classes.collapse_iconBox}>
          {typeof icon === "string" ? <Icon className={classes.collapse_icon}>{icon}</Icon> : icon}
        </ListItemIcon>
        <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
      </AccordionSummary>
      <AccordionDetails sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {subMenu.map(({ id, order, label, link }) => {
          return (
            <SuiBox key={id} sx={{ order: order }}>
              <SubLink to={link} name={label} active={link === `/${collapseName}`} />
            </SuiBox>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string.isRequired,
  subMenu: PropTypes.any,
};

export default SidenavCollapse;
