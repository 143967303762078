import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import rgba from "../../assets/theme/functions/rgba";
import colors from "../../assets/theme/base/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import boxShadow from "../../assets/theme/functions/boxShadow";
import { useState, useEffect } from "react";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import InfoIcon from "Container/Icons/InfoIcon";
import { useSubGetByIdMutation } from "app/features/api/AuthApi";
import { formattedDates } from "helper";
import BaseTableUi from "components/SuiTable/BaseTableUI";
import { useProductDetailsMutation } from "app/features/api/AuthApi";
import Nodata from "layouts/customer/components/Nodata";
import { useInvoiceDetailsMutation } from "app/features/api/InvoiceApi";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { useDownloadInvoiceMutation } from "app/features/api/AuthApi";
import CommonSpinner from "components/CommonSpinner";
import SuiDialog from "components/SuiDialog";
import { useCancelsubMutation } from "app/features/api/AuthApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDeleteProductMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useLocation, useNavigate } from "react-router-dom";
import dialogImg from "../../assets/images/logout-dialog-image.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import AlertIcon from "Container/Icons/AlertIcon";

const SubscriptionDetails = () => {
  const id = localStorage.getItem("sub_id");
  const [productId, setproductId] = useState("");
  const [productRemove, setProductRemove] = useState(false);
  const [cancelSub] = useCancelsubMutation();
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusUpcomingDialogOpen, setUpcomingStatusDialogOpen] = useState(false);
  const navigate = useNavigate();
  const handleDialogClose = (val) => {
    setStatusDialogOpen(val);
  };
  const handleUpcomingDialogClose = (val) => {
    setUpcomingStatusDialogOpen(val);
  };
  const [DeleteProduct] = useDeleteProductMutation();
  const suiSnackbar = useSuiSnackbar();

  const [status, setStatus] = useState("");
  const [CalType, setCalType] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  // const navigateSearch = useNavigate();

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [subInfo, setSubInfo] = useState("");
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };

  const invoiceHeader = [
    { id: "1", width: "auto", align: "left", title: "" },
    { id: "1", width: "auto", align: "left", title: "Payment ID" },
    { id: "2", width: "auto", align: "left", title: "Payment date" },
    { id: "3", width: "auto", align: "left", title: "Payment Method" },
    { id: "4", width: "auto", align: "left", title: "Paid Amount" },
    { id: "5", width: "auto", align: "left", title: "Payment Status" },
    { id: "6", width: "auto", align: "left", title: "Action" },
  ];
  const headerData = [
    {
      title: "Product Type",
    },
    {
      title: "Number",
    },
    {
      title: "SIP Server",
    },
    {
      title: "Number of channels",
    },
    {
      title: "Price",
    },
    {
      title: "Action",
    },
  ];

  const headerData2 = [
    {
      title: "Product Type",
    },
    {
      title: "Number",
    },
    {
      title: "SIP Server",
    },
    {
      title: "Number of channels",
    },
    {
      title: "Price",
    },
  ];
  const handleProductRemove = () => {
    setProductRemove(false);
  };
  const deleteRecord = async () => {
    const params = {
      cancelType: CalType == true ? "1" : "0",
      productId,
    };
    try {
      const response = await DeleteProduct(params).unwrap();
      if (response?.status == "200") {
        suiSnackbar(response.message, "success");
        setProductRemove(false);
        productDetails(id);
      }
    } catch (error) {
      suiSnackbar(error.data.message, "error");
      setProductRemove(false);
      console.log(error);
    }
  };
  const cancelsubss = async () => {
    const params = {
      cancelType: CalType == true ? "1" : "0",
      productId,
    };
    try {
      const response = await cancelSub(params).unwrap();
      suiSnackbar(response.message, "success");
      setProductRemove(false);
      getSubData(id);
      productDetails(id);
      invoiceDetailss(id, currentPage, perPage);
      setproductId("");
      setStatusDialogOpen(false);
      handleUpcomingDialogClose(false);
    } catch (error) {
      suiSnackbar(error.data.message, "error");
      setProductRemove(false);
      console.log(error);
    }
  };
  const testHandle = () => {
    navigate("/subscriptions");
  };
  const upcomingInvoiceHeader = [
    { id: "1", width: "auto", align: "left", title: "Description" },
    { id: "2", width: "auto", align: "left", title: "QTY" },
    { id: "3", width: "auto", align: "right", title: "Unit Price" },
    { id: "4", width: "auto", align: "right", title: "Amount" },
  ];
  const handleProductOpen = (val, id, status, calType) => {
    setProductRemove(val);
    setproductId(id);
    setStatus(status);
    setCalType(calType);
  };

  const [SubGetById, { isLoading: isSubLoad }] = useSubGetByIdMutation();
  const [productInfo, setproductInfo] = useState("");
  const [ProductDetails, { isLoading: isProductLoad }] = useProductDetailsMutation();
  const [DownloadInvoices] = useDownloadInvoiceMutation();
  const [invInfo, setInvInfo] = useState("");
  const [InvoiceDetails, { isLoading: isLoadingInv }] = useInvoiceDetailsMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingId, setLoadingId] = useState(null);

  const [perPageData, setCount] = useState();
  const [perPage, setPerPage] = useState(10);
  // const [productId, setproductId] = useState("");

  const getSubData = async (id) => {
    try {
      const response = await SubGetById(id).unwrap();
      setSubInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const productDetails = async (id) => {
    try {
      const response = await ProductDetails(id).unwrap();
      setproductInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelect = (id) => {
    setSelectedIds(id);
  };
  const invoiceDetailss = async (id, pageNo, perPage) => {
    const params = {
      results_per_page: perPage,
      page: pageNo,
    };
    try {
      const response = await InvoiceDetails({ params, id }).unwrap();
      setInvInfo(response.data);
      setCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const DownloadInvoice = async (id) => {
    setLoadingId(id);
    try {
      const response = await DownloadInvoices(id).unwrap();
      window.open(response.data.invoice_pdf, "_blank");
    } catch (error) {
      suiSnackbar(error.message, "error");
      console.log(error);
    } finally {
      setLoadingId(null);
    }
  };
  useEffect(() => {
    getSubData(id);
    productDetails(id);
    invoiceDetailss(id, currentPage, perPage);
  }, []);
  useEffect(() => {
    invoiceDetailss(id, currentPage, perPage);
  }, [perPage, currentPage]);
  const activeCount =
    productInfo &&
    productInfo?.filter((item) => item.cus_sub_line_items_status === "Active")?.length;
  const handleMenuItemClick = (val, id) => {
    const status = activeCount === 1 ? "active" : "inactive";
    handleProductOpen(
      true,
      activeCount === 1 ? subInfo?.subscription_data?.cus_sub_id : id,
      status,
      val
    );
    handleClose();
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const location = useLocation();
  const { pathname } = location;
  const checkPath = pathname.includes("edit-subscription");
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150,
      textAlign: "center",
      backgroundColor: "#2B2B2B",
      color: "#F2F4FF",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <SuiBox>
        <Grid container pt={{ xs: 2, md: 3, xl: 4 }} rowSpacing={{ xs: 3, lg: 5 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Detail section :: BEGIN */}
              <Grid item xs={12} mb={2}>
                <Box
                  mx="auto"
                  borderRadius={5}
                  bgcolor="#fff"
                  boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                  py={{ xs: 3, lg: 3 }}
                  px={{ xs: 4, lg: 4 }}
                >
                  <Box
                    mb={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <SuiTypography sx={{ fontSize: "18px", color: "#2B2B2B", fontWeight: "600" }}>
                      <ArrowBackIcon
                        onClick={testHandle}
                        sx={{
                          color: "#55DED0",
                          fontSize: "20px",
                          verticalAlign: "middle",
                          cursor: "pointer", // Use 'pointer' to indicate clickable
                        }}
                      />{" "}
                      Subscription Details
                    </SuiTypography>
                    {checkPath && subInfo?.subscription_data?.cus_sub_status == "Active" && (
                      <Box
                        ml="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <SuiButton
                          type="submit"
                          variant="contained"
                          buttonColor="accous"
                          size="small"
                          sx={{
                            padding: "9px 15px",
                          }}
                          onClick={() => {
                            setCalType(true);
                            handleDialogClose(true);
                            setproductId(subInfo?.subscription_data?.cus_sub_id);
                          }}
                        >
                          Immediate Cancel
                        </SuiButton>
                        <SuiButton
                          type="submit"
                          variant="contained"
                          buttonColor="error"
                          size="small"
                          sx={{
                            padding: "8px 15px",
                          }}
                          onClick={() => {
                            handleUpcomingDialogClose(true);
                            setCalType(false);
                            setproductId(subInfo?.subscription_data?.cus_sub_id);
                          }}
                        >
                          Cancel Subscription
                        </SuiButton>
                      </Box>
                    )}
                  </Box>
                  {subInfo?.subscription_data?.cus_sub_status == "Cancelled" && (
                    <Box
                      mx="auto"
                      borderRadius={2}
                      p={{ xs: 3, lg: 2 }}
                      borderColor={rgba(colors.error.main, 1)}
                      sx={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SuiButton
                            type="submit"
                            variant="contained"
                            buttonColor="error"
                            size="md"
                          >
                            Cancelled
                          </SuiButton>
                        </Grid>
                        <Grid item xs={6} md={2} alignItems="flex-start">
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Subscription ID:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {subInfo?.subscription_data?.cus_sub_stripe_id}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Purchased on:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {formattedDates(subInfo?.subscription_data?.sub_purchase_on)}{" "}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                              width: "145px",
                            }}
                          >
                            Account Number:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.main} !important`,
                            }}
                          >
                            {subInfo?.subscription_data?.customers?.cus_account_number}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Cancelled on:
                          </SuiTypography>
                        </Grid>

                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {formattedDates(subInfo?.subscription_data?.cus_sub_cancelled_on)}{" "}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                              width: "145px",
                            }}
                          >
                            Customer Company Name:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.main} !important`,
                            }}
                          >
                            {subInfo?.subscription_data?.customers?.cus_company_name}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Cancelled by:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_name}
                            {""}{" "}
                            {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                              ? subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                              : ""}
                          </SuiTypography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {subInfo?.subscription_data?.cus_sub_status == "Active" && (
                    <Box
                      mx="auto"
                      borderRadius={2}
                      p={{ xs: 3, lg: 2 }}
                      borderColor={rgba(colors.primary.main, 1)}
                      sx={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SuiButton
                            type="submit"
                            variant="contained"
                            buttonColor="primary"
                            size="md"
                          >
                            {subInfo?.subscription_data?.cus_sub_status}
                          </SuiButton>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Subscription ID:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.cus_sub_stripe_id}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Account Number:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_account_number}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Customer Company Name:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_company_name}
                            </SuiTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "160px",
                              }}
                            >
                              Purchased on:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {formattedDates(subInfo.subscription_data?.sub_purchase_on)}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "160px",
                              }}
                            >
                              Current Period:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {formattedDates(subInfo.subscription_data?.current_period_start)} to{" "}
                              {formattedDates(subInfo.subscription_data?.current_period_end)}{" "}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "160px",
                              }}
                            >
                              Next Payment Date:
                            </SuiTypography>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {formattedDates(subInfo.subscription_data?.next_payment_date)}{" "}
                            </SuiTypography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {subInfo?.subscription_data?.cus_sub_status == "Cancels soon" && (
                    <Box
                      mx="auto"
                      borderRadius={2}
                      p={{ xs: 3, lg: 2 }}
                      borderColor={rgba(colors.text.focus, 1)}
                      sx={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12}>
                          <SuiButton
                            type="submit"
                            variant="contained"
                            size="md"
                            sx={{
                              background: `${colors.text.focus} !important`,
                              color: `${colors.white.main} !important`,
                            }}
                          >
                            {subInfo?.subscription_data?.cus_sub_status}
                          </SuiButton>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            fontWeight="semiBold"
                            ml={1}
                            sx={{
                              color: `${colors.info.main} !important`,
                            }}
                          >
                            Effective from{" "}
                            {formattedDates(subInfo?.subscription_data?.current_period_end)}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2} alignItems="flex-start">
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Subscription ID:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {subInfo?.subscription_data?.cus_sub_stripe_id}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Purchased on:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {formattedDates(subInfo?.subscription_data?.sub_purchase_on)}{" "}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                              width: "145px",
                            }}
                          >
                            Account Number:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.main} !important`,
                            }}
                          >
                            {subInfo?.subscription_data?.customers?.cus_account_number}
                          </SuiTypography>
                        </Grid>

                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Cancelled on:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {formattedDates(subInfo?.subscription_data?.cus_sub_cancelled_on)}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                              width: "145px",
                            }}
                          >
                            Customer Company Name:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.main} !important`,
                            }}
                          >
                            {subInfo?.subscription_data?.customers?.cus_company_name}
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                            sx={{
                              color: `${colors.text.focus} !important`,
                            }}
                          >
                            Cancelled by:
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontSize={{ xs: 14, md: 15, xl: 16 }}
                          >
                            {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_name}
                            {""}{" "}
                            {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                              ? subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                              : subInfo?.subscription_data?.cus_sub_cancelled_by === null &&
                                subInfo?.subscription_data?.cus_sub_cancelled_reference_type ===
                                  "Customer"
                              ? "Self"
                              : "-"}
                          </SuiTypography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {/* Upcoming Invoice table :: BEGIN */}
                  <Box
                    borderRadius={2}
                    mt={3}
                    borderColor={rgba(colors.text.focus, 0.3)}
                    sx={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      item
                      px={2}
                      py={2}
                      sx={{
                        background: `${rgba(colors.secondary.main, 0.6)}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <SuiTypography
                        component="span"
                        variant="body2"
                        fontWeight="semiBold"
                        fontSize={{ xs: 14, md: 16, xl: 18 }}
                        sx={{
                          color: `${colors.text.main} !important`,
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Upcoming Invoice
                        {subInfo?.subscription_data?.cus_sub_status == "Active" && (
                          <CustomWidthTooltip
                            arrow
                            title={`This is a preview of invoice that will be generated on ${formattedDates(
                              subInfo.subscription_data?.next_payment_date
                            )}. It may change if the subscription is updated.`}
                          >
                            <span>
                              <InfoIcon />
                            </span>
                          </CustomWidthTooltip>
                        )}
                      </SuiTypography>
                      {subInfo.upcoming_invoice_data?.period_start?.length > 0 && (
                        <SuiTypography
                          component="span"
                          variant="body2"
                          fontWeight="semiBold"
                          fontSize={{ xs: 12, md: 14, xl: 15 }}
                          sx={{
                            color: `${colors.info.main} !important`,
                          }}
                        >
                          {formattedDates(subInfo.upcoming_invoice_data?.period_start)} to{" "}
                          {formattedDates(subInfo.upcoming_invoice_data?.period_end)}{" "}
                        </SuiTypography>
                      )}
                    </Grid>
                    <Box px={2} py={2}>
                      <BaseTableUi
                        tableHeadData={
                          subInfo?.upcoming_invoice_products_data?.length == 0
                            ? []
                            : upcomingInvoiceHeader
                        }
                      >
                        {isSubLoad ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <CommonSpinner size="100px" color={colors.primary.main} />
                            </TableCell>
                          </TableRow>
                        ) : subInfo?.upcoming_invoice_products_data?.length == 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} sx={{ padding: "0 5px" }}>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"start"}
                                width={"100%"}
                                height={"100%"}
                              >
                                <SuiTypography
                                  variant="body2"
                                  fontSize="14px"
                                  sx={{
                                    color: `${colors.text.focus} !important`,
                                  }}
                                >
                                  No Upcoming Invoices.
                                </SuiTypography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          subInfo?.upcoming_invoice_products_data?.map((val, i) => (
                            <TableRow key={i}>
                              <TableCell sx={{ minWidth: "150px" }}>{val.product_name}</TableCell>
                              <TableCell sx={{ minWidth: "130px" }}>{val.quantity}</TableCell>
                              <TableCell sx={{ minWidth: "130px" }} align="right">
                                £{val.unit_amount_excluding_tax}
                              </TableCell>
                              <TableCell sx={{ minWidth: "100px" }} align="right">
                                £{val.amount}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </BaseTableUi>
                    </Box>
                    {subInfo.upcoming_invoice_data?.period_start?.length > 0 && (
                      <Box
                        px={2}
                        py={2}
                        className="tablewithoutBorder tableBgTransparent"
                        sx={{
                          background: `${rgba(colors.secondary.main, 0.6)}`,
                        }}
                      >
                        <Box
                          px={2}
                          py={2}
                          className="tablewithoutBorder"
                          sx={{
                            background: `${rgba(colors.secondary.focus, 0.3)}`,
                          }}
                        >
                          {subInfo.upcoming_invoice_data?.period_start?.length > 0 && (
                            <BaseTableUi tableHeadData={[]}>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "150px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "130px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "130px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    width="200px"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                      textAlign: "right",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Subtotal
                                  </SuiTypography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "100px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                    }}
                                  >
                                    £{subInfo.upcoming_invoice_data?.subtotal}
                                  </SuiTypography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "150px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "130px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "130px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    width="200px"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                      textAlign: "right",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Total excluding tax
                                  </SuiTypography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "100px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                    }}
                                  >
                                    £{subInfo.upcoming_invoice_data?.subtotal_excluding_tax}
                                  </SuiTypography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "150px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "130px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "130px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    width="200px"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                      textAlign: "right",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Total
                                  </SuiTypography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "100px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                    }}
                                  >
                                    £{subInfo.upcoming_invoice_data?.total}{" "}
                                  </SuiTypography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "150px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "0",
                                    minWidth: "130px",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "130px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    width="200px"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                      textAlign: "right",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Amount due
                                  </SuiTypography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "0", minWidth: "100px" }}
                                  align="right"
                                >
                                  <SuiTypography
                                    component="span"
                                    variant="body2"
                                    fontSize={{ xs: 14, xl: 16 }}
                                    fontWeight="bold"
                                    sx={{
                                      color: `${colors.text.main} !important`,
                                    }}
                                  >
                                    £{subInfo.upcoming_invoice_data?.amount_due}{" "}
                                  </SuiTypography>
                                </TableCell>
                              </TableRow>
                            </BaseTableUi>
                          )}
                        </Box>{" "}
                      </Box>
                    )}
                  </Box>
                  {/* Upcoming Invoice table :: END */}
                  {/* Number Section :: BEGIN */}
                  <Grid item xs={12}>
                    <Box
                      borderRadius={2}
                      bgcolor="#fff"
                      boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                      mt={3}
                      borderColor={rgba(colors.text.focus, 0.3)}
                      sx={{
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid
                        item
                        px={2}
                        py={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <SuiTypography
                          component="span"
                          variant="body2"
                          fontWeight="semiBold"
                          fontSize={{ xs: 14, md: 16, xl: 18 }}
                          sx={{
                            color: `${colors.text.main} !important`,
                          }}
                        >
                          Product Details
                        </SuiTypography>
                      </Grid>
                      <BaseTableUi
                        tableHeadData={
                          subInfo?.subscription_data?.cus_sub_status == "Active" && checkPath
                            ? headerData
                            : headerData2
                        }
                      >
                        {isProductLoad ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <CommonSpinner size="100px" color={colors.primary.main} />
                            </TableCell>
                          </TableRow>
                        ) : productInfo?.length == 0 ? (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Nodata />
                            </TableCell>
                          </TableRow>
                        ) : (
                          productInfo &&
                          productInfo?.map((val, i) => (
                            <TableRow key={i}>
                              <TableCell>{val.cus_sub_line_items_product_type}</TableCell>
                              <TableCell>{val.cus_sub_line_items_product_json.number}</TableCell>
                              <TableCell>
                                {val.cus_sub_line_items_product_json.sip_username}
                              </TableCell>
                              <TableCell>
                                {val.cus_sub_line_items_product_json.sip_channels}
                              </TableCell>
                              <TableCell>£{val.cus_sub_line_items_price}/Month</TableCell>
                              {subInfo?.subscription_data?.cus_sub_status == "Active" &&
                                checkPath && (
                                  <TableCell>
                                    <>
                                      {val.cus_sub_line_items_status == "Active" && (
                                        <div>
                                          <IconButton
                                            onClick={(e) => {
                                              setAnchorEl(e.currentTarget);
                                              handleSelect(val.cus_sub_line_items_id);
                                            }}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>

                                          <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                          >
                                            <MenuItem
                                              onClick={() =>
                                                handleMenuItemClick(
                                                  false,
                                                  activeCount == 1
                                                    ? subInfo?.subscription_data?.cus_sub_id
                                                    : selectedIds
                                                )
                                              }
                                            >
                                              Remove Product
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleMenuItemClick(
                                                  true,
                                                  activeCount == 1
                                                    ? subInfo?.subscription_data?.cus_sub_id
                                                    : selectedIds
                                                )
                                              }
                                            >
                                              Immediate Remove
                                            </MenuItem>
                                          </Menu>
                                        </div>
                                      )}
                                      {val.cus_sub_line_items_status == "Cancels Soon" && (
                                        <p
                                          style={{
                                            color: "#364FB3",
                                          }}
                                        >
                                          Cancels on{" "}
                                          {formattedDates(val.cus_sub_line_items_cancelled_on)}
                                        </p>
                                      )}
                                      {val.cus_sub_line_items_status == "Cancelled" && (
                                        <p
                                          style={{
                                            color: "#C02B0A",
                                          }}
                                        >
                                          Removed
                                        </p>
                                      )}
                                    </>
                                  </TableCell>
                                )}
                            </TableRow>
                          ))
                        )}
                      </BaseTableUi>
                    </Box>
                  </Grid>
                  {/* Number Section :: END */}
                  {/* Invoice Section :: BEGIN */}
                  <Grid item xs={12}>
                    <Box
                      borderRadius={2}
                      bgcolor="#fff"
                      boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                      mt={3}
                      borderColor={rgba(colors.text.focus, 0.3)}
                      sx={{
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      <Grid
                        item
                        px={2}
                        py={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <SuiTypography
                          component="span"
                          variant="body2"
                          fontWeight="semiBold"
                          fontSize={{ xs: 14, md: 16, xl: 18 }}
                          sx={{
                            color: `${colors.text.main} !important`,
                          }}
                        >
                          Invoices
                        </SuiTypography>
                      </Grid>
                      <BaseTableUi tableHeadData={invoiceHeader}>
                        {isLoadingInv ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <CommonSpinner size="100px" color={colors.primary.main} />
                            </TableCell>
                          </TableRow>
                        ) : invInfo?.rows?.length == undefined ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Nodata />
                            </TableCell>
                          </TableRow>
                        ) : (
                          invInfo?.rows?.map((val, i) => (
                            <TableRow
                              key={i}
                              sx={
                                val.cus_invoice_payment_status === "Due"
                                  ? { background: `${rgba(colors.secondary.main, 0.6)}` }
                                  : {}
                              }
                            >
                              <TableCell>
                                {val.cus_invoice_payment_status === "Due" ? (
                                  <Tooltip
                                    title={
                                      <>
                                        <SuiTypography
                                          textColor="light"
                                          fontWeight="regular"
                                          variant="body2"
                                          sx={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {subInfo?.subscription_data?.cus_sub_overdue?.text}
                                        </SuiTypography>
                                      </>
                                    }
                                    arrow
                                    placement="bottom"
                                    backgroundColor="#000000"
                                  >
                                    <IconButton sx={{ padding: "0" }}>
                                      <AlertIcon fontSize={12} color="error" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell>{val.cus_invoice_payment_intent_id}</TableCell>
                              <TableCell>
                                {formattedDates(val.cus_invoice_stripe_created_at)}
                              </TableCell>
                              <TableCell>{val.cus_invoice_payment_method}</TableCell>
                              <TableCell>£{val.cus_invoice_amount}</TableCell>
                              <TableCell>
                                {val.cus_invoice_payment_status == "Paid" && (
                                  <SuiTypography
                                    textColor="info"
                                    fontSize="inherit"
                                    fontWeight="semiBold"
                                  >
                                    {val.cus_invoice_payment_status}
                                  </SuiTypography>
                                )}
                                {val.cus_invoice_payment_status == "Due" && (
                                  <SuiTypography
                                    textColor="error"
                                    fontSize="inherit"
                                    fontWeight="semiBold"
                                  >
                                    {val.cus_invoice_payment_status}
                                  </SuiTypography>
                                )}
                              </TableCell>
                              <TableCell>
                                <SuiButton
                                  type="submit"
                                  variant="contained"
                                  disabled={loadingId == val.cus_invoice_stripe_id}
                                  buttonColor="info"
                                  onClick={() => DownloadInvoice(val.cus_invoice_stripe_id)}
                                  size="small"
                                >
                                  {loadingId == val.cus_invoice_stripe_id ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    "Download"
                                  )}{" "}
                                </SuiButton>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </BaseTableUi>
                      {/* Pagination section :: BEGIN */}
                      {!isLoadingInv && perPageData > 0 && (
                        <Grid
                          container
                          py={3}
                          spacing={1.5}
                          flexDirection={{ xs: "column", md: "row" }}
                          justifyContent={{ xs: "center", md: "flex-end" }}
                          alignItems="center"
                        >
                          <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <span>Show</span>
                              </Grid>
                              <Grid item>
                                {/* Select dropdown for items per page */}
                                <Select
                                  value={perPage}
                                  onChange={handlePerPageChange}
                                  variant="standard"
                                  // onClick={() => getCustomerMultipleRecords(perPage, currentPage)}
                                  sx={{ minWidth: 100 }}
                                >
                                  {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item>
                                <span>out of {perPageData} Results</span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <CommonPagination
                              mt={0}
                              totalData={perPageData}
                              pageSize={perPage}
                              currentPage={Number(currentPage)}
                              onPageChange={handlePageChange}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Pagination section :: END */}
                    </Box>
                  </Grid>
                  {/* Invoice Section :: END */}
                </Box>
              </Grid>
              <SuiDialog
                open={statusDialogOpen}
                onClose={() => handleDialogClose(false)}
                haveCloseIcon
              >
                <DialogContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <img src={dialogImg} />
                  <SuiTypography fontWeight="semiBold" sx={{ mt: 2, fontSize: "16px !important" }}>
                    Are you sure you want to cancel the subscription immediately?
                  </SuiTypography>
                  <SuiTypography
                    fontWeight="regular"
                    sx={{
                      mt: 2,
                      fontSize: "12px !important",
                      color: `${colors.secondary.focus} !important`,
                    }}
                  >
                    Cancellation will be effective immediately from now.
                  </SuiTypography>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} sx={{ marginTop: "20px" }}>
                      <Grid container justifyContent={"center"} spacing={2}>
                        <Grid item xs={12} sm="auto">
                          <SuiButton
                            size="small"
                            sx={{ px: "1rem" }}
                            onClick={() => handleDialogClose(false)}
                            buttonColor="dark"
                            variant="outlined"
                            fullWidth
                          >
                            No
                          </SuiButton>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <SuiButton
                            size="small"
                            sx={{ px: "1rem" }}
                            buttonColor="info"
                            fullWidth
                            onClick={() => cancelsubss()}
                          >
                            Yes
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </SuiDialog>
              <SuiDialog
                open={statusUpcomingDialogOpen}
                onClose={() => handleUpcomingDialogClose(false)}
                haveCloseIcon
              >
                <DialogContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <img src={dialogImg} />
                  <SuiTypography fontWeight="semiBold" sx={{ mt: 2, fontSize: "16px !important" }}>
                    Are you sure you want to cancel the upcoming subscription?
                  </SuiTypography>
                  <SuiTypography
                    fontWeight="regular"
                    sx={{
                      mt: 2,
                      fontSize: "12px !important",
                      color: `${colors.secondary.focus} !important`,
                    }}
                  >
                    Cancellation will be effective from upcoming billing cycle.
                  </SuiTypography>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} sx={{ marginTop: "20px" }}>
                      <Grid container justifyContent={"center"} spacing={2}>
                        <Grid item xs={12} sm="auto">
                          <SuiButton
                            size="small"
                            sx={{ px: "1rem" }}
                            onClick={() => handleUpcomingDialogClose(false)}
                            buttonColor="dark"
                            variant="outlined"
                            fullWidth
                          >
                            No
                          </SuiButton>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <SuiButton
                            size="small"
                            sx={{ px: "1rem" }}
                            buttonColor="info"
                            fullWidth
                            onClick={() => cancelsubss()}
                          >
                            Yes
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </SuiDialog>
              <SuiDialog open={productRemove} onClose={handleProductRemove} maxWidth="sm" fullWidth>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  px={2}
                  py={3}
                  spacing={3}
                >
                  <Grid item>
                    <Box
                      component="img"
                      maxWidth="100%"
                      height="auto"
                      width={100}
                      src={dialogImg}
                      alt="logout image"
                    />
                  </Grid>
                  <Grid item>
                    <SuiTypography
                      textAlign="center"
                      component="h4"
                      fontSize={{ xs: 18, lg: 20 }}
                      fontWeight="semiBold"
                      lineHeight={1.4}
                    >
                      {status != "active" && !CalType
                        ? "Are you sure you want to remove the product from the upcoming billing cycle?"
                        : status == "active" && !CalType
                        ? "Are you sure you want to cancel the upcoming subscription?"
                        : CalType
                        ? "Are you sure you want to remove this product from this subscription immediately?"
                        : "Please confirm your action."}
                      {/* {status != "active"
                        ? "Are you sure you want to remove the product from the upcoming billing cycle?"
                        : "Are you sure you want to cancel the upcoming subscription?"} */}
                    </SuiTypography>
                    {!CalType && (
                      <SuiTypography
                        fontWeight="regular"
                        textAlign="center"
                        sx={{
                          mt: 2,
                          fontSize: "12px !important",
                          color: `${colors.secondary.focus} !important`,
                        }}
                      >
                        Cancellation will be effective from upcoming billing cycle.
                      </SuiTypography>
                    )}
                    {CalType && (
                      <SuiTypography
                        fontWeight="regular"
                        textAlign="center"
                        sx={{
                          mt: 2,
                          fontSize: "12px !important",
                          color: `${colors.secondary.focus} !important`,
                        }}
                      >
                        Cancellation will be effective immediately from now.
                      </SuiTypography>
                    )}
                  </Grid>
                  <Grid item>
                    <Box display="flex" columnGap={2}>
                      <SuiButton
                        variant="contained"
                        buttonColor="accous"
                        size="small"
                        sx={{ fontSize: 14 }}
                        onClick={handleProductRemove}
                      >
                        No
                      </SuiButton>
                      <SuiButton
                        variant="contained"
                        buttonColor="primary"
                        size="small"
                        sx={{ fontSize: 14 }}
                        onClick={() => {
                          status != "active" ? deleteRecord() : cancelsubss();
                        }}
                      >
                        Yes
                      </SuiButton>
                    </Box>
                  </Grid>
                </Grid>
              </SuiDialog>
              {/* Detail section :: END */}
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default SubscriptionDetails;
