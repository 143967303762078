import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import {
  Box,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Toolbar,
  AppBar,
  Skeleton,
  Stack,
  Divider,
  ListItemIcon,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import breakpoints from "assets/theme/base/breakpoints";
import Breadcrumbs from "Container/Breadcrumbs";
import styles from "Container/DashboardNavbar/styles";
import { stringAvatar, capitalize } from "helper";
import { updateUI } from "app/features/UIControllerSlice";
import profileImage from "assets/images/profile-img.png";
import SuiTypography from "components/SuiTypography";
import Logo from "assets/images/fuse2-logo.png";
import { Logout } from "app/features/AuthSlice";
import { useLogoutMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useGetAdminInfoMutation } from "app/features/api/AuthApi";
import CronStatus from "./CronStatus";
import { useHandleGetApiQuery } from "app/features/api/CommonGetApi";
import { setPermission } from "app/features/AuthSlice";
import NotificationContainer from "./NotificationConatiner";
import NotificationIcon from "Container/Icons/NotificationIcon";
import ProfileIcon from "Container/Icons/ProfileIcon";
import LogoutIcon from "Container/Icons/LogoutIcon";
import { Permission } from "Permissions/Permission";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import GlobalIcon from "Container/Icons/GlobalIcon";
import { useGetAllGlobalMutation } from "app/features/api/AuthApi";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [cronStatus, setCronStatus] = useState();
  const dispatch = useDispatch();
  const [GetAllGlobal] = useGetAllGlobalMutation();
  const [globalInfo, setGlobalInfo] = useState({});
  const [globalFullInfo, setGlobalFullInfo] = useState({});

  console.log(globalInfo, "globalInfo");
  const navigate = useNavigate();
  const [getAdminInfo, { isLoading }] = useGetAdminInfoMutation();
  const [getUser, setGetUser] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const fetchAdminInfo = async () => {
    const response = await getAdminInfo().unwrap();
    try {
      if (response.status === 200) {
        setGetUser(response.data);
        dispatch(setPermission(response.data?.permissions));
      }
    } catch (error) {
      // console.log(response);
    }
  };
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  let name = getUser.admin_name;
  if (getUser && !isLoading) {
    name = `${getUser.admin_name} `;
  }
  useEffect(() => {
    fetchAdminInfo();
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      dispatch({
        type: "transparentNavbar",
        value: (fixedNavbar && window.scrollY !== 0) || !fixedNavbar,
      });
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const displayMobileNavbar = () => {
      if (window.innerWidth < breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener("resize", displayMobileNavbar);

    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const handleMiniSidenav = () => dispatch(updateUI({ type: "miniSidenav", value: !miniSidenav }));
  const datas = isLoading;
  const [logout] = useLogoutMutation();
  const accountMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const token = localStorage.getItem("auth_token_admin");
    const suiSnackbar = useSuiSnackbar();
    const logoutHandler = async () => {
      const headers = {
        Authorization: token,
      };
      const response = await logout(headers).unwrap();
      try {
        if (response.status === 200) {
          suiSnackbar(response.message, "success");
        }
      } catch (error) {
        // console.log(error);
      }
      dispatch(Logout());

      navigate("/");
    };
    const { data, error, refetch } = useHandleGetApiQuery({
      url: "/admin/cron-status",
    });
    // console.log(data, permissionInfo?.cron?.VIEW, "permissiii");
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (permissionInfo?.cron?.VIEW) {
          refetch();
        }
      }, 10000);

      return () => clearInterval(intervalId);
    }, [refetch, permissionInfo?.cron?.VIEW]);

    useEffect(() => {
      if (data) {
        setCronStatus(data);
      }
      if (error) {
        // console.log(error, "error");
      }
    }, [data, error]);
    useEffect(() => {
      const fetchGlobalInfo = async () => {
        try {
          const response = await GetAllGlobal().unwrap();
          setGlobalInfo(response.data[0].global_config_slug?.replace(/_/g, "-"));
          setGlobalFullInfo(response.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchGlobalInfo();
    }, []);
    useEffect(() => {
      const userPermissions = matchPermissions(permissions, Permission);
      setPermissionInfo(userPermissions);
    }, [getUser]);
    return (
      <>
        <Box className={classes.avatar_container} onClick={!datas ? handleClick : () => null}>
          {!mobileView ? (
            datas ? (
              <Skeleton height={25} width={80} />
            ) : (
              <Box className="userName" title={name} component="span">
                {capitalize(name)}
              </Box>
            )
          ) : null}
          {datas ? (
            <Skeleton variant="circular" sx={{ marginLeft: "10px" }}>
              <Avatar />
            </Skeleton>
          ) : (
            <IconButton
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              title={name}
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar src={profileImage} alt={name} {...stringAvatar(name, profileImage)} />
            </IconButton>
          )}
          <Icon fontSize="small">keyboard_arrow_down</Icon>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          disableScrollLock={true}
        >
          {mobileView ? (
            <>
              <Stack direction="row" sx={{ alignItems: "center" }} spacing={1.2}>
                <Avatar
                  src={profileImage}
                  alt={name}
                  {...stringAvatar(name, profileImage, "32px")}
                />
                <SuiTypography fontSize={14} fontWeight="semiBold">
                  {capitalize(name)}
                </SuiTypography>
              </Stack>
              {permissionInfo?.cron?.VIEW && <CronStatus />}
              <Divider />
            </>
          ) : null}
          <MenuItem component={Link} to="/profile">
            <ListItemIcon>
              <ProfileIcon size={17} color="text" />
            </ListItemIcon>
            My Account
          </MenuItem>
          {permissionInfo?.global_config?.VIEW && (
            <MenuItem component={Link} to={`/global-configuration/${globalInfo}`}>
              <ListItemIcon>
                <GlobalIcon size={17} color="text" />
              </ListItemIcon>
              Global Configuration
            </MenuItem>
          )}
          <MenuItem component={Link} to="/notification">
            <ListItemIcon>
              <NotificationIcon size={17} color="text" />
            </ListItemIcon>
            Notification
          </MenuItem>

          <MenuItem
            sx={{
              color: "#FC3C3C",
              "&:hover": { bgcolor: "#FC3C3C", color: "#fff" },
            }}
            onClick={logoutHandler}
          >
            <ListItemIcon>
              <LogoutIcon size={17} color="text" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  };
  return (
    <Box position={"sticky"} top={0} zIndex={10}>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        className={classes.navbar}
      >
        <Toolbar className={classes.navbar_container}>
          <SuiBox component={Link} display={{ xs: "none", md: "block" }} to="/dashboard">
            <SuiBox
              component="img"
              src={Logo}
              alt="PolEasy Logo"
              customClass={classes.sidenav_logo}
            />
          </SuiBox>
          <SuiBox customClass={classes.navbar_row} color="inherit" mr="auto" mb={{ xs: 1, md: 0 }}>
            <Breadcrumbs
              icon="home"
              title={route[route.length - (route.length < 3 ? 1 : 3)]}
              route={route}
              light={light}
              globalInfo={globalFullInfo}
            />
          </SuiBox>

          {!mobileView && permissionInfo?.cron?.VIEW && <CronStatus status={cronStatus?.data} />}
          <SuiBox sx={{ mx: 1 }}>
            <NotificationContainer />
          </SuiBox>
          {isMini ? null : (
            <SuiBox customClass={classes.navbar_row}>
              <SuiBox
                color={light ? "white" : "inherit"}
                customClass={classes.navbar_section_desktop}
              >
                <IconButton
                  size="medium"
                  color="inherit"
                  className={classes.navbar_mobile_menu}
                  onClick={handleMiniSidenav}
                >
                  <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>
                {accountMenu()}
              </SuiBox>
            </SuiBox>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
