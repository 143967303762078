import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import HistoryIcon from "@mui/icons-material/History";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SidenavLink from "Container/Sidenav/SidenavLink";
import SidenavCollapse from "Container/Sidenav/SidenavCollapse";
import styles from "Container/Sidenav/styles/sidenav";
import Logo from "assets/images/fuse2-logo.png";
import { updateUI } from "app/features/UIControllerSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import PricingIcon from "Container/Icons/PricingIcon";
import DidIcon from "Container/Icons/DidIcon";
import DashboardIcon from "Container/Icons/DashboardIcon";
import RoleIcon from "Container/Icons/RoleIcon";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomerIcon from "Container/Icons/CustomerIcon";
import ThreeCXIcon from "Container/Icons/ThreeCXIcon";
import SubscriptionIcon from "Container/Icons/SubscriptionIcon";
import RequestIcon from "Container/Icons/RequestIcon";

const Sidenav = ({ ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav } = uiControl;
  const classes = styles({ miniSidenav, transparentSidenav });
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const closeSidenav = () => dispatch(updateUI({ type: "miniSidenav", value: true }));

  const { permissions } = useSelector((state) => state.auth);
  const userPermissions = matchPermissions(permissions, Permission);

  useEffect(() => {
    dispatch(updateUI({ type: "miniSidenav", value: tabView }));
  }, [dispatch, location, tabView]);
  const rootChildrens = [];
  const rootChildrenRoutes = {
    dashboard: {
      id: 1,
      type: "link",
      label: "Dashboard",
      link: "/dashboard",
      icon: <DashboardIcon size={14} />,
    },
    roles: {
      id: 2,
      type: "link",
      label: "Roles & Permission",
      link: "/roles-&-permission",
      icon: <RoleIcon size={16} />,
    },
    user_management: {
      id: 3,
      type: "link",
      label: "User Management",
      link: "/user-management",
      icon: <PersonOutlineOutlinedIcon sx={{ fontSize: 21, marginLeft: "-5px" }} fontSize="20" />,
    },
    customer: {
      id: 4,
      type: "link",
      label: "Customer",
      link: "/customer",
      icon: <CustomerIcon size={18} />,
    },
    did_management: {
      id: 5,
      type: "collapse",
      label: "DID Management",
      icon: <DidIcon size={18} />,
      sub_menu: [
        {
          id: 5.1,
          order: 1,
          label: "Assign DIDs",
          link: "/assign-dids",
          permission: "Assigndid",
        },
        {
          id: 5.2,
          order: 2,
          label: "DID",
          link: "/did",
          permission: "didNum",
        },
        // {
        //   id: 5.3,
        //   order: 3,
        //   label: "Removal Requests",
        //   link: "/removal-requests",
        //   permission: "did_removal",
        // },
      ],
    },
    threecx_licences: {
      id: 6,
      type: "collapse",
      label: "3CX Licences",
      icon: <ThreeCXIcon size={18} />,
      sub_menu: [
        {
          id: 6.1,
          order: 1,
          label: "3CX Details",
          link: "/3cx-details",
          permission: "threecx_licences",
        },
        {
          id: 6.2,
          order: 2,
          label: "3CX Request Approval",
          link: "/3cx-request-approval",
          permission: "threecx_licences_request",
        },
      ],
    },
    removal_request: {
      id: 7,
      type: "link",
      label: "Removal Requests",
      link: "/removal-requests",
      permission: "removal_request",
      icon: <RequestIcon size={16} />,
    },
    subscription: {
      id: 8,
      type: "link",
      label: "Subscriptions",
      link: "/subscriptions",
      icon: <SubscriptionIcon size={14} />,
      sub_menu: [
        {
          id: 7.1,
          order: 1,
          link: "/view-subscription",
        },
      ],
    },
    price: {
      id: 9,
      type: "link",
      label: "Pricing",
      link: "/pricing",
      icon: <PricingIcon size={18} />,
    },
    sites_pages: {
      id: 10,
      type: "link",
      label: "Sites & pages",
      link: "/pages",
      icon: <DescriptionIcon size={18} />,
    },
    cron: {
      id: 11,
      type: "link",
      label: "Cron History",
      link: "/cron-history",
      icon: <HistoryIcon sx={{ fontSize: 18 }} fontSize="18" />,
    },
  };

  rootChildrenRoutes.did_management.sub_menu = rootChildrenRoutes.did_management.sub_menu.filter(
    (item) => userPermissions?.[item.permission]?.VIEW
  );

  // Check if any submenu items are available for DID Management and push it to rootChildrens
  if (rootChildrenRoutes.did_management.sub_menu.length > 0) {
    rootChildrens.push(rootChildrenRoutes.did_management);
  }

  rootChildrenRoutes.threecx_licences.sub_menu =
    rootChildrenRoutes.threecx_licences.sub_menu.filter(
      (item) => userPermissions?.[item.permission]?.VIEW
    );

  if (rootChildrenRoutes.threecx_licences.sub_menu.length > 0) {
    rootChildrens.push(rootChildrenRoutes.threecx_licences);
  }

  // Push other root children based on permissions
  [
    "price",
    "dashboard",
    "roles",
    "user_management",
    "subscription",
    "sites_pages",
    "removal_request",
    "cron",
    "customer",
  ].forEach((el) => {
    if (userPermissions?.[el]?.VIEW) {
      rootChildrens.push(rootChildrenRoutes?.[el]);
    }
  });
  useEffect(() => {}, [rootChildrens]);

  const renderRoutes = rootChildrens.map(({ id, icon, link, label, sub_menu, type }) => {
    let returnValue;
    if (type === "link") {
      returnValue = (
        <SidenavLink to={link} name={label} icon={icon} active={link === `/${collapseName}`} />
      );
    } else if (type === "collapse") {
      returnValue = <SidenavCollapse name={label} icon={icon} subMenu={sub_menu} />;
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          customClass={classes.sidenav_title}
        >
          {label}
        </SuiTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider />;
    }
    return (
      <ListItem component="li" key={`${id}`} sx={{ order: id }}>
        {returnValue}
      </ListItem>
    );
  });

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSidenav}
        >
          <SuiTypography variant="h6" textColor="dark" sx={{ cursor: "pointer" }}>
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={Link} to="/dashboard">
          <SuiBox
            component="img"
            src={Logo}
            alt="PolEasy Logo"
            customClass={classes.sidenav_logo}
          />
        </SuiBox>
      </SuiBox>
      <Divider sx={{ display: { xs: "block", xl: "none" } }} />
      <List className={classes.sidenav_list}>{renderRoutes}</List>
    </Drawer>
  );
};

export default Sidenav;
