export const Permission = {
  didNum: {
    CREATE: "did-create",
    VIEW: "did-view",
    DELETE: "did-delete",
    UPDATE: "did-update",
  },
  price: {
    CREATE: "pricing-create",
    VIEW: "pricing-view",
    DELETE: "pricing-delete",
    UPDATE: "pricing-upate",
  },
  sites_pages: {
    CREATE: "sites_pages-create",
    VIEW: "sites_pages-view",
    DELETE: "sites_pages-delete",
    UPDATE: "sites_pages-update",
  },
  cron: {
    CREATE: "cron_history-view",
    VIEW: "cron_history-view",
    DELETE: "cron_history-delete",
    UPDATE: "cron_history-update",
  },
  Assigndid: {
    CREATE: "assign_dids-create",
    VIEW: "assign_dids-view",
    DELETE: "assign_dids-delete",
    UPDATE: "assign_dids-update",
  },
  dashboard: {
    CREATE: "dashboard-create",
    VIEW: "dashboard-view",
    DELETE: "dashboard-delete",
    UPDATE: "dashboard-update",
  },
  user_management: {
    CREATE: "user_management-create",
    VIEW: "user_management-view",
    DELETE: "user_management-delete",
    UPDATE: "user_management-update",
  },
  customer: {
    CREATE: "customer-create",
    VIEW: "customer-view",
    DELETE: "customer-delete",
    UPDATE: "customer-update",
  },
  dids: {
    CREATE: "did_management-create",
    VIEW: "did_management-view",
    DELETE: "did_management-delete",
    UPDATE: "did_management-update",
  },
  removal_request: {
    VIEW: "removal_request-view",
    UPDATE: "removal_request-update",
  },
  roles: {
    CREATE: "role_permissions-create",
    VIEW: "role_permissions-view",
    DELETE: "role_permissions-delete",
    UPDATE: "role_permissions-update",
  },
  threecx_licences: {
    CREATE: "3cx_licences_details-create",
    VIEW: "3cx_licences_details-view",
    DELETE: "3cx_licences_details-delete",
    UPDATE: "3cx_licences_details-update",
  },
  threecx_licences_request: {
    CREATE: "3cx_licences_request-create",
    VIEW: "3cx_licences_request-view",
    DELETE: "3cx_licences_request-delete",
    UPDATE: "3cx_licences_request-update",
  },
  subscription: {
    CREATE: "subscription-create",
    VIEW: "subscription-view",
    DELETE: "subscription-delete",
    UPDATE: "subscription-update",
  },
  global_config: {
    CREATE: "global_configuration-create",
    VIEW: "global_configuration-view",
    DELETE: "global_configuration-delete",
    UPDATE: "global_configuration-update",
  },
};
