import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, transitions, breakpoints, typography, functions }) => {
  const { dark, white, text, gradients, transparent, primary, text2 } = palette;
  const { fontWeightRegular, fontWeightSemiBold, size } = typography;
  const { pxToRem } = functions;

  return {
    collapse_item: {
      background: transparent.main,
      width: "100%",
      padding: `0 !important`,
      margin: `0 ${pxToRem(8)} !important`,
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow: "none",
      "& .MuiAccordionSummary-root": {
        padding: `0 ${pxToRem(8)} 0 ${pxToRem(15)} !important`,
      },
      "& .MuiAccordionDetails-root": {
        padding: "0px 0px 0px 15px",
      },
    },
    collapse_summary: {
      borderRadius: "0",
      color: ({ active }) => (active ? dark.main : text.light),
      minHeight: "44px !important",
      "& > .MuiAccordionSummary-content": {
        alignItems: "center",
        margin: "0 !important",
      },
    },
    collapse_iconBox: {
      minWidth: pxToRem(32),
      minHeight: pxToRem(32),
      borderRadius: "0",
      display: "grid",
      placeItems: "center",
      boxShadow: "none",
      transition: transitions.create("margin", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      [breakpoints.up("xl")]: {
        background: transparent.main,
      },

      "& svg, svg g": {
        fill: ({ active }) => (active ? primary.main : text2.main),
      },
    },

    collapse_icon: {
      color: ({ active }) => (active ? white.main : gradients.dark.state),
    },

    collapse_text: {
      marginLeft: pxToRem(2),

      [breakpoints.up("xl")]: {
        opacity: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
        maxWidth: ({ miniSidenav, transparentSidenav }) =>
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: ({ active }) => (active ? fontWeightSemiBold : fontWeightRegular),
        fontSize: size.sm,
        lineHeight: 0,
        color: "#303841",
      },
    },
  };
});
