export const didTableHeader = [
  {
    id: "1",
    align: "left",
    width: "300",
    label: "Number",
  },
  {
    id: "2",
    align: "left",
    width: "150",
    label: "Type",
  },
  {
    id: "12",
    align: "left",
    width: "auto",
    label: "IS Diverted",
  },
  {
    id: "3",
    align: "left",
    width: "300",
    label: "Diverted to",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Country",
  },
  {
    id: "6",
    align: "left",
    width: "100",
    label: "Rate",
  },
  {
    id: "8",
    align: "left",
    width: "auto",
    label: "Account Number",
  },
  {
    id: "9",
    align: "left",
    width: "auto",
    label: "Purchased On",
  },
  // {
  //   id: "10",
  //   align: "right",
  //   width: "auto",
  //   label: "ACTION",
  // },
];
export const pagesTableHeader = [
  {
    id: "1",
    align: "left",
    width: "100%",
    label: "Page Name",
  },
  // {
  //   id: "12",
  //   align: "left",
  //   width: "auto",
  //   label: "Action",
  // },
];
export const requestApprovalTableHeader = [
  {
    id: "1",
    align: "left",
    width: "auto",
    label: "Account Number",
  },

  {
    id: "2",
    align: "left",
    width: "auto",
    label: "Product Type",
  },
  {
    id: "3",
    align: "left",
    width: "auto",
    label: "Requested On",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Status",
  },
  {
    id: "5",
    align: "left",
    width: "auto",
    label: "Updated On",
  },
  {
    id: "5",
    align: "left",
    width: "auto",
    label: "Updated By",
  },
  {
    id: "5",
    align: "right",
    width: "auto",
    label: "Action",
  },
];
export const doughnutCharDataFn = (label, labels, data, backgroundColors) => ({
  labels: [...labels],
  datasets: {
    label,
    data: [...data],
    backgroundColors,
    borderWidth: 0,
  },
});
export const doughnutCharDataLc = (label, labels, data, backgroundColors) => ({
  labels: [...labels],
  datasets: {
    label,
    data: [...data],
    backgroundColors,
    borderWidth: 0,
  },
});
