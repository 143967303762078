import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  didNumbers: [],
  sip: [],
  transactions: [],
  discount: [],
  orders: [],
  paymentInvoice: [],
  callRecordList: [],
  SpendLimits: "",
};

export const customerDetailsSlice = createSlice({
  initialState,
  name: "customerDetailsSlice",
  reducers: {
    setDidNumbers: (state, { payload }) => {
      state.didNumbers = payload;
    },
    setSipDetails: (state, { payload }) => {
      state.sip = payload;
    },
    setTransactionDetails: (state, { payload }) => {
      state.transactions = payload;
    },
    setPaymentInvoieReport: (state, { payload }) => {
      state.paymentInvoice = payload;
    },
    setDiscountDetails: (state, { payload }) => {
      state.discount = payload;
    },
    setOrderDetails: (state, { payload }) => {
      state.orders = payload;
    },
    setCallRecordReport: (state, { payload }) => {
      state.callRecordList = payload;
    },
    setSpendLimitData: (state, { payload }) => {
      state.SpendLimits = payload;
    },
    resetCustomerDetails: (state) => {
      state.didNumbers = [];
      state.transactions = [];
      state.discount = [];
    },
  },
});

export const {
  setDidNumbers,
  setSipDetails,
  setSpendLimitData,
  setTransactionDetails,
  setPaymentInvoieReport,
  setDiscountDetails,
  setOrderDetails,
  resetCustomerDetails,
  setCallRecordReport,
} = customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
