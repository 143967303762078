import { Outlet } from "react-router-dom";
import CollapseFormLayout from "Container/CollapseFormLayout";
import { useGetAllGlobalMutation } from "app/features/api/AuthApi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalInfo } from "app/features/AuthSlice";

function GlobalConfig() {
  const [GetAllGlobal] = useGetAllGlobalMutation();
  const { globalInfo } = useSelector((state) => state.auth);
  console.log(globalInfo, "globalInfo");
  const dispatch = useDispatch();
  const SideInfo = async () => {
    try {
      const response = await GetAllGlobal().unwrap();
      console.log(response);
      dispatch(setGlobalInfo(response.data));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    SideInfo();
  }, []);

  const slugToLabelMap = (Array.isArray(globalInfo) ? globalInfo : []).reduce((map, config) => {
    map[config.global_config_slug] = config.global_config_label;
    return map;
  }, {});

  // Define the routes array
  const routes = (Array.isArray(globalInfo) ? globalInfo : []).map((config) => ({
    name: slugToLabelMap[config.global_config_slug],
    to: `/global-configuration/${config.global_config_slug.replace(/_/g, "-")}`,
  }));
  return (
    <CollapseFormLayout routes={routes}>
      <Outlet />
    </CollapseFormLayout>
  );
}

export default GlobalConfig;
